import React from "react";
import styles from "./boxTitle.module.scss";

export interface BoxTitleProps {
  title: string;
}

const BoxTitle: React.FC<BoxTitleProps> = ({ title }) => (
  <div className={styles.boxTitle}>{title}</div>
);

export default BoxTitle;
