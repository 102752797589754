export const OAP_API_PARAMS = (
  method: string = "GET",
  token?: string,
  body?: { [key: string]: any }
  ): RequestInit => ({  
    method,
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      client_id: process.env.REACT_APP_OAP_EXPERIENCE_API_CLIENT_ID || "",
      client_secret: process.env.REACT_APP_OAP_EXPERIENCE_API_CLIENT_SECRET || "",
      "Token": `${token}`,
    },
  });
  
  export async function httpRequest(
  url?: string,
  params?: RequestInit
): Promise<any> {
  if (!url) {
    throw new Error("Missing request URL.");
  }

  const response: Response = await fetch(url, params);

  if (!response.ok) {
    throw new Error(`Unable to request resourse from: ${url}`);
  }

  return await response.json()
}
