import React from "react";
import styles from "./searchBox.module.scss";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";

export interface SearchBoxProps {
  placeholder?: string;
  value?: string;
  onChange: Function;
}

export const SearchBox: React.FC<SearchBoxProps> = ({
  placeholder = "Search",
  onChange,
  value = "",
}) => {

  return (
    <div className={styles.searchBox__wrapper}>
      <input
        type="text"
        className={styles.searchBox__input}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      
      <div className={styles.searchBox__iconWrapper}>
        <Icon path={mdiMagnify} title={"Expand"} size={"1.5rem"} />
      </div>
    </div>
  );
};
