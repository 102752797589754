import { Endpoints } from "../../constants/endpoints";
import { httpRequest, OAP_API_PARAMS } from "../../utils/request";

export const getApis = async () =>
  await httpRequest(Endpoints.apis(), OAP_API_PARAMS());
export const getApisFilter = async (layer: string, env: string, deepProduct: string) =>
  await httpRequest(Endpoints.apisFilter(layer, env, deepProduct), OAP_API_PARAMS());
export const getApiById = async (groupId: string, apiId: string) =>
  await httpRequest(
    Endpoints.apiByGroupIdApiId(groupId, apiId),
    OAP_API_PARAMS()
  );
