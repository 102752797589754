import React from "react";
import styles from "./borderedBox.module.scss";

const BorderedBox: React.FC = ({ children }) => (
  <div data-testid="BorderedBoxComponent" className={styles.bordered__wrapper}>
    {children}
  </div>
);

export default BorderedBox;
