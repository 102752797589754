import { System } from "../../types/System";
import { RootState } from "../rootReducer";

export const systemListSelector = (state: RootState): System[] => {
  const filterValue = state.systems.filterValue.toLowerCase();
  if (!filterValue) {
    return state.systems.results;
  }
  return state.systems.results.filter((system: System) =>
    system.name.toLowerCase().includes(filterValue)
  );
};

export const systemSuggestionsSelector = (state: RootState): System[] =>
  state.systems.suggestions;

export const systemsFilterValueSelector = (state: RootState): string =>
  state.systems.filterValue;

export const selectedSystemSelector = (state: RootState): System | undefined =>
  state.systems.selected;

export const systemsLoadingSelector = (state: RootState): boolean =>
  state.systems.loading;
