import React from "react";
import cn from "classnames";
import styles from "./ctaButton.module.scss";

type CTAButtonIntrisic = JSX.IntrinsicElements["button"];

export interface CTAButtonProps extends CTAButtonIntrisic {
  ["data-testid"]?: string;
  isSecondary?: boolean;
}

const CTAButton: React.FC<CTAButtonProps> = (props) => {
  const { isSecondary, ...rest } = props;
  return (
    <button
      {...rest}
      className={cn(styles.cta_button, props.className, {
        [styles.secondary]: isSecondary,
      })}
    >
      {props.children}
    </button>
  );
};

export { CTAButton };
