import React, { useCallback } from "react";
import styles from "./detailsReviewField.module.scss";
import { ApiReview } from "../../types/ApiReview";
import { DetailsField } from "../DetailsField/DetailsField.component";
import {
  SolutionDesignReviewLabels,
  APISpecReviewLabels,
  CodeReviewLabels,
  StatusValueLabels,
  Placeholder,
} from "../../constants/labels";

const solutionDesignFilterArray = [
  "reviewId",
  "pageId",
  "version",
  "solutionName",
  "deepProduct",
  "groupId",
  "solutionUrl",
  "jiraTicketId",
  "approved",
  "approvalDate",
  "approverId",
  "lastUpdateDate",
  "lastUpdatedBy",
];

const filteredSolutionDesignReviewDetails = (
  detailsLabelArray: string[]
): string[] =>
  detailsLabelArray.filter((key: string) =>
    solutionDesignFilterArray.includes(key)
  );

const apiSpecFilterArray = [
  "assetId",
  "solutionDesignPageId",
  "deepProduct",
  "version",
  "groupId",
  "jiraTicketId",
  "approved",
  "approvalDate",
  "approverId",
  "lastUpdateDate",
  "lastUpdatedBy",
  "qaReviewUrl",
];

const filteredAPISpecReviewDetails = (detailsLabelArray: string[]): string[] =>
  detailsLabelArray.filter((key: string) => apiSpecFilterArray.includes(key));

const codeFilterArray = [
  "artefactId",
  "solutionDesignPageId",
  "assetId",
  "deepProduct",
  "version",
  "groupId",
  "jiraTicketId",
  "approved",
  "approvalDate",
  "approverId",
  "lastUpdateDate",
  "lastUpdatedBy",
  "qaReviewUrl",
];

const filteredCodeReviewDetails = (detailsLabelArray: string[]): string[] =>
  detailsLabelArray.filter((key: string) => codeFilterArray.includes(key));

export interface DetailsReviewFieldProps {
  apiLatestReview?: ApiReview;
}

export const DetailsReviewField: React.FC<DetailsReviewFieldProps> = ({
  apiLatestReview,
}) => {
  const solutionDesignReviewDetailsKeys: string[] =
    filteredSolutionDesignReviewDetails(
      Object.keys(apiLatestReview?.review.solutionDesign || {})
    );

  const apiSpecReviewDetailsKeys: string[] = filteredAPISpecReviewDetails(
    Object.keys(apiLatestReview?.review.apiSpec || {})
  );

  const codeReviewDetailsKeys: string[] = filteredCodeReviewDetails(
    Object.keys(apiLatestReview?.review.code || {})
  );

  const formatApiValues = useCallback(
    (name, value) => {
      if (!apiLatestReview) {
        return value;
      }
      const toApprovedFormat = (approvedStatus: boolean): string => {
        switch (approvedStatus) {
          case true:
            return StatusValueLabels.True;
          case false:
            return StatusValueLabels.False;
          default:
            return StatusValueLabels.ToBeReviewed;
        }
      };
      const formatJiraTicketId = (ticketId: string) => {
        if (ticketId === null || ticketId === Placeholder.NotAvailable) {
          return <span>{Placeholder.NotAvailable}</span>;
        }
        const isJiraTicket = ticketId.startsWith("G");
        const jiraLink = isJiraTicket
          ? (ticketId = `https://jira.app.pconnect.biz/browse/${ticketId}`)
          : ticketId;
        const displayText = ticketId.startsWith("https")
          ? ticketId.split("/")[4]
          : ticketId;
        return (
          <a className={styles.history_Link} href={jiraLink} title={ticketId}>
            {displayText}
          </a>
        );
      };
      const handlers: { [key: string]: (v: any) => any } = {
        creationDate: (value: string) => new Date(value).toUTCString(),
        approved: toApprovedFormat,
        solutionUrl: (value: string) => (
          <a className={styles.history_Link} href={value} title={value}>
            {value === null ? Placeholder.NotAvailable : "Link"}
          </a>
        ),
        qaReviewUrl: (value: string) => (
          <a className={styles.history_Link} href={value} title={value}>
            {value === null ? Placeholder.NotAvailable : "Link"}
          </a>
        ),
        jiraTicketId: formatJiraTicketId,
        approvalDate: (value: string) => new Date(value).toUTCString(),
        lastUpdateDate: (value: string) => new Date(value).toUTCString(),
      };
      return handlers[name] ? handlers[name](value) : value;
    },
    [apiLatestReview]
  );

  return (
    <div className={styles.review_wrapper}>
      <div>
        <h3 className={styles.review_title}>Solution Design</h3>
        <div className={styles.wrapper}>
          {!!apiLatestReview &&
            (
              solutionDesignReviewDetailsKeys as Array<
                keyof typeof apiLatestReview.review.solutionDesign
              >
            ).map((apikey) => (
              <DetailsField
                key={apikey}
                handler={apikey}
                name={SolutionDesignReviewLabels[apikey]}
                value={formatApiValues(
                  apikey,
                  apiLatestReview.review.solutionDesign[apikey]
                )}
              />
            ))}
        </div>
      </div>

      <div>
        <h3 className={styles.review_title}>API Specification</h3>
        <div className={styles.wrapper}>
          {!!apiLatestReview &&
            (
              apiSpecReviewDetailsKeys as Array<
                keyof typeof apiLatestReview.review.apiSpec
              >
            ).map((apikey) => (
              <DetailsField
                key={apikey}
                handler={apikey}
                name={APISpecReviewLabels[apikey]}
                value={formatApiValues(
                  apikey,
                  apiLatestReview.review.apiSpec[apikey]
                )}
              />
            ))}
        </div>
      </div>

      <div>
        <h3 className={styles.review_title}>Application Code</h3>
        <div className={styles.wrapper}>
          {!!apiLatestReview &&
            (
              codeReviewDetailsKeys as Array<keyof typeof apiLatestReview.review.code>
            ).map((apikey) => (
              <DetailsField
                key={apikey}
                handler={apikey}
                name={CodeReviewLabels[apikey]}
                value={formatApiValues(apikey, apiLatestReview.review.code[apikey])}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
