import React from "react";
import styles from "./pageHeader.module.scss";

export interface PageHeaderProps {
  bookmark?: String;
  title: String;
  subtitle?: String;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  bookmark,
  title,
  subtitle,
}) => {
  return (
    <div className={styles.pageHeader__wrapper}>
      {!!bookmark && (
        <div className={styles.pageHeader__bookmark}>{bookmark}</div>
      )}
      <div className={styles.pageHeader__title}>{title}</div>
      {!!subtitle && (
        <div className={styles.pageHeader__subtitle}>{subtitle}</div>
      )}
    </div>
  );
};
