import React, { useEffect } from "react";
import { APIListComponent } from "./APIList.component";
import { useDispatch, useSelector } from "react-redux";
import {
  apiListSelector,
  apisFilterValueSelector,
  apisLoadingSelector,
} from "../../store/apis/selectors";
import {
  listApisAction,
  setApisFilterValueAction,
} from "../../store/apis/actions";
import { withAuth } from "../../components/withAuth/withAuth";

const APIListContainer: React.FC = () => {
  const dispatch = useDispatch();
  const apis = useSelector(apiListSelector);
  const searchValue = useSelector(apisFilterValueSelector);
  const onSearch = (value: string) => dispatch(setApisFilterValueAction(value));
  const loading = useSelector(apisLoadingSelector);

  useEffect(() => {
    dispatch(listApisAction());
  }, [dispatch]);

  const props = {
    apis,
    searchValue,
    onSearch,
    loading,
  };

  return <APIListComponent {...props} />;
};

export default withAuth(APIListContainer);
