import React from "react";
import { Compliance } from "../../types/Compliance";
import { ScorePercentageCard } from "../ScorePercentageCard/ScorePercentageCard.component";
import styles from "./scoreHeader.module.scss";
import Icon from "@mdi/react";
import { mdiWeight, mdiCircleSlice5 } from "@mdi/js";
import { PageHeader } from "../PageHeader/PageHeader.component";
import { GoBackButton } from "../GoBackButton/GoBackButton.component";

export interface ScoreHeaderProps {
  compliance: Compliance;
  apiName: String;
  apiProduct: String;
}

const ICON_SIZE = "1.5rem";

export const ScoreHeader: React.FC<ScoreHeaderProps> = ({
  compliance,
  apiName,
  apiProduct,
}) => {
  return (
    <div className={styles.scoreHeader__wrapper}>
      <div className={styles.scoreHeader_left}>
        <div className={styles.score_header}>
          <PageHeader
            bookmark="Score Details"
            title={apiName}
            subtitle={apiProduct}
          />
          <GoBackButton />
        </div>
        <div className={styles.scoreHeader__legend}>
          <div className={styles.scoreHeader__legend_weight}>
            <Icon path={mdiWeight} title="Weight" size={ICON_SIZE} />
            <div className={styles.scoreHeader__legend_label}>
              weight of criteria
            </div>
          </div>
          <div className={styles.scoreHeader__legend_value}>
            <Icon path={mdiCircleSlice5} title="Completion" size={ICON_SIZE} />
            <div className={styles.scoreHeader__legend_label}>
              completion of criteria
            </div>
          </div>
        </div>
      </div>
      <div className={styles.scoreHeader_right}>
        <ScorePercentageCard value={compliance.complianceLevel} />
      </div>
    </div>
  );
};
