import React, { useCallback } from "react";
import { ApiException } from "../../types/ApiException";
import styles from "./detailsExceptionField.module.scss";
import { DetailsField } from "../DetailsField/DetailsField.component";
import {
  APIExceptionLabels,
  StatusValueLabels,
  Placeholder,
} from "../../constants/labels";

const exceptionFilterArrayCH = [
  "approvedDeploymentTarget",
  "approved",
  "approvedWorkerSize",
  "approvalDate",
  "approvedWorkerNumber",
  "approvalEndDate",
  "approverId",
  "lastUpdatedBy",
  "solutionDesignPageId",
  "lastUpdateDate",
];

const exceptionFilterArrayRTF = [
  "approvedDeploymentTarget",
  "approved",
  "approvedReplicas",
  "approvalDate",
  "approvedCpuLimit",
  "approvalEndDate",
  "approvedCpuReserved",
  "approverId",
  "approvedMemory",
  "lastUpdatedBy",
  "solutionDesignPageId",
  "lastUpdateDate",
];

const filteredExceptionDetailsCH = (detailsLabelArray: string[]): string[] =>
  detailsLabelArray.filter((key: string) =>
    exceptionFilterArrayCH.includes(key)
  );

const filteredExceptionDetailsRTF = (detailsLabelArray: string[]): string[] =>
  detailsLabelArray.filter((key: string) =>
    exceptionFilterArrayRTF.includes(key)
  );

export interface DetailsExceptionFieldProps {
  apiException?: ApiException;
}

export const DetailsExceptionField: React.FC<DetailsExceptionFieldProps> = ({
  apiException,
}) => {
  const exceptionDetailsKeysCH: string[] = filteredExceptionDetailsCH(
    Object.keys(apiException || {})
  );

  const exceptionDetailsKeysRTF: string[] = filteredExceptionDetailsRTF(
    Object.keys(apiException || {})
  );

  const formatApiValues = useCallback(
    (name, value) => {
      if (!apiException) {
        return value;
      }
      const toApprovedFormat = (approvedStatus: boolean): string => {
        switch (approvedStatus) {
          case true:
            return StatusValueLabels.True;
          default:
            return StatusValueLabels.False;
        }
      };
      const handlers: { [key: string]: (v: any) => any } = {
        creationDate: (value: string) => new Date(value).toUTCString(),
        approved: toApprovedFormat,
        solutionUrl: (value: string) => (
          <a className={styles.history_Link} href={value} title={value}>
            {value === null ? Placeholder.NotAvailable : "Link"}
          </a>
        ),
        approvalDate: (value: string) => new Date(value).toUTCString(),
        approvalEndDate: (value: string) => new Date(value).toUTCString(),
        lastUpdateDate: (value: string) => new Date(value).toUTCString(),
      };
      return handlers[name] ? handlers[name](value) : value;
    },
    [apiException]
  );

  return (
    <div className={styles.exception_wrapper}>
      <div>
        <div className={styles.wrapper}>
          {!!apiException &&
          (apiException.approvedDeploymentTarget === "CloudHub" ||
            apiException.approvedDeploymentTarget === "CH")
            ? (exceptionDetailsKeysCH as Array<keyof typeof apiException>).map(
                (apiExceptionkey) => (
                  <DetailsField
                    key={apiExceptionkey + apiException.exceptionId}
                    handler={apiExceptionkey}
                    name={APIExceptionLabels[apiExceptionkey]}
                    value={formatApiValues(
                      apiExceptionkey,
                      apiException[apiExceptionkey]
                    )}
                  />
                )
              )
            : (exceptionDetailsKeysRTF as Array<keyof typeof apiException>).map(
                (apiExceptionkey) => (
                  <DetailsField
                    key={apiExceptionkey + apiException!.exceptionId}
                    handler={apiExceptionkey}
                    name={APIExceptionLabels[apiExceptionkey]}
                    value={formatApiValues(
                      apiExceptionkey,
                      apiException![apiExceptionkey]
                    )}
                  />
                )
              )}
        </div>
      </div>
    </div>
  );
};
