import React from "react";
import cn from "classnames";
import { STEP, Step } from "../../types/Compliance";
import styles from "./stepListItem.module.scss";
import Icon from "@mdi/react";
import { mdiCheck, mdiClose } from "@mdi/js";
import { WeightLabel } from "../WeightLabel/WeightLabel.component";

export interface StepListItemProps {
  step: Step;
}

export const StepListItem: React.FC<StepListItemProps> = ({ step }) => {
  const iconProps = {
    path: step.status === STEP.PASSED ? mdiCheck : mdiClose,
    title: step.status === STEP.PASSED ? "Step passed" : "Step failed",
    size: "1.5rem",
  };

  return (
    <div className={styles.step__listItem}>
      <div className={styles.step__left}>
        <WeightLabel weight={step.weight} />
        <div className={styles.step__label}>{step.description}</div>
      </div>
      <div
        className={cn(styles.step__right, {
          [styles.step__right_failed]: step.status === STEP.FAILED,
        })}
      >
        <Icon {...iconProps} />
      </div>
    </div>
  );
};
