import { call, put, takeEvery } from "redux-saga/effects";
import {
  API_ACTIONS,
  FetchApiActionType,
  SetListApisFilterActionType,
  setApisLoadingAction,
  setListApisAction,
  setSelectedApiAction,
} from "./actions";
import { Api } from "../../types/Api";
import {
  getApiById,
  getApis,
  getApisFilter,
} from "./requests";

export function* listApisSaga() {
  try {
    yield put(setApisLoadingAction(true));
    const result: Api[] = yield call(getApis);
    yield put(setListApisAction(result));
  } catch (e) {
    yield put(setApisLoadingAction(false));
  }
}

export function* listApisFilterSaga({ payload: { layer, env, deepProduct } }: SetListApisFilterActionType) {
  try {
    yield put(setApisLoadingAction(true));
    const result: Api[] = yield call(getApisFilter, layer, env, deepProduct);
    yield put(setListApisAction(result));
  } catch (e) {
    yield put(setApisLoadingAction(false));
  }
}

export function* fetchApiSaga({
  payload: { groupId, apiId },
}: FetchApiActionType) {
  yield put(setApisLoadingAction(true));
  const result: Api = yield call(getApiById, groupId, apiId);
  yield put(setSelectedApiAction(result));
}

export function* apisSaga() {
  yield takeEvery(API_ACTIONS.LIST, listApisSaga);
  yield takeEvery(API_ACTIONS.LIST_API_FILTER, listApisFilterSaga);
  yield takeEvery(API_ACTIONS.FETCH_ONE, fetchApiSaga);
}

export default apisSaga;