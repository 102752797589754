const OAP_BASE_URL = process.env.REACT_APP_OAP_EXPERIENCE_API;

export const Endpoints = Object.freeze({
  //graph
  userPermissions: () => `${OAP_BASE_URL}/user-permissions`,
  //apis
  apis: () => `${OAP_BASE_URL}/apis`,
  apisFilter: (layer: string, env: string, deepProduct: string) =>
    `${OAP_BASE_URL}/apis?layer=${layer}&environment=${env}&deepProduct=${deepProduct}`,
  apiByGroupIdApiId: (groupId: string, apiId: string) =>
    `${OAP_BASE_URL}/organizations/${groupId}/apis/${apiId}`,
  apiReportByGroupIdApiId: (groupId: string, apiId: string) =>
    `${OAP_BASE_URL}/report/organizations/${groupId}/apis/${apiId}`,
  //apisReview for C4E
  apisReview: () => `${OAP_BASE_URL}/apis/c4e-reviews`,
  apisReviewByReviewTypeSolution: () =>
    `${OAP_BASE_URL}/apis/c4e-reviews?reviewType=Solution`,
  apisReviewByReviewTypeAPISpec: () =>
    `${OAP_BASE_URL}/apis/c4e-reviews?reviewType=Specification`,
  apisReviewByGroupIdApiId: (groupId: string, apiId: string) =>
    `${OAP_BASE_URL}/organizations/${groupId}/apis/${apiId}/c4e-reviews`,
  apiLatestReviewByGroupIdApiId: (groupId: string, apiId: string) =>
    `${OAP_BASE_URL}/organizations/${groupId}/apis/${apiId}/c4e-reviews?latestRecord=true`,
  //apisExceptions
  apisException: () => `${OAP_BASE_URL}/apis/exceptions`,
  apisExceptionStatus: () => `${OAP_BASE_URL}/apis/exceptions/status`,
  apisExceptionStatusByGroupIDApiId: (groupId: string, apiId: string) => 
    `${OAP_BASE_URL}/organizations/${groupId}/apis/${apiId}/exceptions/status`,
  apisExceptionByGroupIdApiId: (groupId: string, apiId: string, latest: boolean) =>
    `${OAP_BASE_URL}/organizations/${groupId}/apis/${apiId}/exceptions?latestRecord=${latest}`,
  apiLatestException: (groupId: string, apiId: string) =>
    `${OAP_BASE_URL}/organizations/${groupId}/apis/${apiId}/exceptions?latestRecord=true`,
  //score
  scoreDetails: () => process.env.REACT_APP_QA_REPORT_LINK,
  //systems
  systems: () => `${OAP_BASE_URL}/applications`,
  systemById: (systemCode: string) =>
    `${OAP_BASE_URL}/applications/${systemCode}`,
  systemSuggestions: (groupId: string, apiId: string) =>
    `${OAP_BASE_URL}/organizations/${groupId}/apis/${apiId}/applications/suggestions`,
  associateApiWithSystem: (groupId: string, apiId: string, version: string) =>
    `${OAP_BASE_URL}/organizations/${groupId}/apis/${apiId}/version/${version}/applications`,
});
