import { combineReducers } from "redux";
import { complianceReducer, ComplianceState } from "./compliance/reducer";
import { apiReducer, ApiState } from "./apis/reducer";
import { apiReviewReducer, ApiReviewState } from "./apisReview/reducer";
import { apiExceptionReducer, ApiExceptionState } from "./apisException/reducer";
import { systemsReducer, SystemState } from "./systems/reducer";
import { authReducer, AuthState } from "./auth/reducer";

export interface RootState {
  compliance: ComplianceState;
  apis: ApiState;
  apisReview: ApiReviewState;
  apisException: ApiExceptionState;
  systems: SystemState;
  auth: AuthState;
}

const rootReducer = combineReducers({
  compliance: complianceReducer,
  apis: apiReducer,
  apisReview: apiReviewReducer,
  apisException: apiExceptionReducer,
  systems: systemsReducer,
  auth: authReducer,
});

export default rootReducer;
