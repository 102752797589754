import React from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import styles from "./navigationBar.module.scss";
import { ReactComponent as Logo } from "../../assets/img/logo.dark.svg";
import Icon from "@mdi/react";
import {
  mdiHexagonMultiple,
  mdiFaceAgent,
  mdiWrench,
  mdiFolderPlusOutline,
} from "@mdi/js";
import { Link, useLocation } from "react-router-dom";
import { NAV_SECTIONS, ROUTES, ROUTE_ENTITIES } from "../../constants/routes";
import { InfoTooltip } from "../InfoTooltip/InfoTooltip.component";
import {
  TitleLabels,
  SupportContacts,
  isRoleAllowedMessage,
} from "../../constants/labels";
import { authRoleMessageSelector } from "../../store/auth/selectors";

const ICON_SIZE = "1.5rem";

export const NavigationBar: React.FC = () => {
  const location = useLocation();
  const isRoleAllowed = useSelector(authRoleMessageSelector);

  return (
    <div className={styles.nav}>
      {isRoleAllowed !== "" && (
        <div className={styles.bar}>
          <Link to={ROUTES.home}>
            <div className={styles.logo}>
              <Logo />
            </div>
          </Link>
          <Link to={ROUTES.apiList}>
            <div
              className={cn(styles.icon, {
                [styles.active]: location.pathname.startsWith(
                  `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.apis}`
                ),
              })}
            >
              <Icon
                path={mdiHexagonMultiple}
                title={TitleLabels.governance}
                size={ICON_SIZE}
              />
            </div>
          </Link>
          <Link to={ROUTES.apiReviewList}>
            <div
              className={cn(styles.icon, {
                [styles.active]:
                  location.pathname.startsWith(
                    `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.reviews}`
                  ) ||
                  location.pathname.startsWith(
                    `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.exceptions}`
                  ),
              })}
            >
              <Icon
                path={mdiWrench}
                title={TitleLabels.reviews}
                size={ICON_SIZE}
              />
            </div>
          </Link>
          {isRoleAllowed === isRoleAllowedMessage.Allowed && (
            <Link to={ROUTES.create}>
              <div
                className={cn(styles.icon, {
                  [styles.active]: location.pathname.startsWith(
                    `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.create}`
                  ),
                })}
              >
                <Icon
                  path={mdiFolderPlusOutline}
                  title={TitleLabels.createReviews}
                  size={ICON_SIZE}
                />
              </div>
            </Link>
          )}

          <div className={styles.support}>
            <InfoTooltip>
              <InfoTooltip.Holder>
                <div className={cn(styles.icon, styles.bordered)}>
                  <Icon
                    path={mdiFaceAgent}
                    title={TitleLabels.support}
                    size={ICON_SIZE}
                  />
                </div>
              </InfoTooltip.Holder>
              <InfoTooltip.Content>
                <div>For help and support: </div>
                <a href={`mailto:${SupportContacts.Email}`}>
                  {SupportContacts.Email}
                </a>
              </InfoTooltip.Content>
            </InfoTooltip>
          </div>
        </div>
      )}
    </div>
  );
};
