import React from "react";
import { useAuth } from "../../hooks/useAuth";
import { Loader } from "../Loader/Loader.component";

export interface AuthExtraInfo {
  isAuthenticated: boolean;
  token?: string;
}

export function withAuth<P>(
  WrappedComponent: React.ComponentType<P & AuthExtraInfo>
) {
  return (props: P) => {
    const { isAuthenticated, token } = useAuth();
    if (!isAuthenticated) {
      return <Loader />;
    }

    return <WrappedComponent {...props} isAuthenticated token={token} />;
  };
}
