import produce from "immer";
import { Compliance } from "../../types/Compliance";

import {
  ComplianceActionsTypes,
  COMPLIANCE_ACTIONS,
  SetComplianceLoadingActionType,
  SetComplianceReportActionType,
} from "./actions";

export interface ComplianceState {
  result: Compliance;
  loading: boolean;
}

const initialState = {
  result: {} as Compliance,
  loading: true,
};

export const complianceReducer = produce(
  (
    draftState: ComplianceState = initialState,
    action?: ComplianceActionsTypes
  ) => {
    switch (action?.type) {
      case COMPLIANCE_ACTIONS.SET_LOADING: {
        const safeAction = action as SetComplianceLoadingActionType;
        draftState.loading = safeAction.payload;
        return draftState;
      }
      case COMPLIANCE_ACTIONS.SET_REPORT: {
        const safeAction = action as SetComplianceReportActionType;
        draftState.result = safeAction.payload;
        draftState.loading = false;
        return draftState;
      }

      default: {
        return draftState;
      }
    }
  }
);
