import React, { useCallback } from "react";
import styles from "./validationListItem.module.scss";
import { Validation } from "../../types/Compliance";
import { CollapsibleContent } from "../CollapsibleContent/CollapsibleContent.component";
import { StepListItem } from "../StepListItem/StepListItem.component";
import { WeightLabel } from "../WeightLabel/WeightLabel.component";
import { CompletionPercentage } from "../CompletionPercentage/CompletionPercentage.component";
import { useCollapsable } from "../../hooks/useCollapsable";

export interface ValidationListItemProps {
  validation: Validation;
}

export const ValidationListItem: React.FC<ValidationListItemProps> = ({
  validation,
}) => {
  const [collapsed, toggleCollapsed] = useCollapsable();

  const handleClick = useCallback(() => {
    toggleCollapsed();
  }, [toggleCollapsed]);

  return (
    <div className={styles.validation__listItem}>
      <div className={styles.validation__header}>
        <div className={styles.validation__header_left}>
          <WeightLabel weight={validation.weight} />
          <div className={styles.validation__label}>
            {validation.description}
          </div>
        </div>
        <div className={styles.validation__header_right}>
          <CompletionPercentage
            value={validation.value}
            onClickExpand={handleClick}
            collapsed={collapsed}
          />
        </div>
      </div>
      <div className={styles.validation__collapsible}>
        <CollapsibleContent collapsed={collapsed}>
          {validation.steps.map((step) => (
            <StepListItem key={step.description} step={step} />
          ))}
        </CollapsibleContent>
      </div>
    </div>
  );
};
