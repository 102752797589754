import React, { useCallback } from "react";
import cn from "classnames";
import { Api } from "../../types/Api";
import { Card } from "../Card/Card.component";
import styles from "./apiCard.module.scss";
import { useNavigate } from "react-router-dom";
import { ScorePercentageBar } from "../ScorePercentageBar/ScorePercentageBar.component";
import { Placeholder } from "../../constants/labels";
import { LINK_ENTITIES } from "../../constants/routes";

export interface APICardProps {
  api: Api;
}

export const APICard: React.FC<APICardProps> = ({ api }) => {
  const navigate = useNavigate();
  const hasSystem = api.systemName !== Placeholder.NotAvailable;

  const handleClick = useCallback(() => {
    navigate(`${LINK_ENTITIES.api(api.id, api.groupId)}`);
  }, [ navigate, api]);

  return (
    <Card onClick={handleClick} className={styles.show_pointer}>
      <div className={styles.top}>
        <div className={styles.details}>
          <div className={styles.name} title={api.name}>
            {api.name}
          </div>
          <div
            className={cn(styles.system, {
              [styles.unavailable]: !hasSystem,
            })}
          >
            {hasSystem ? api.systemName : "Missing system!"}
          </div>
          <div className={styles.platform}>{api.apiPlatform}</div>
        </div>
        <div className={styles.version}>{api.version}</div>
      </div>

      <div className={styles.bottom}>
        <ScorePercentageBar api={api} />
        <div className={styles.owner}>{api.owner}</div>
      </div>
    </Card>
  );
};
