import React from "react";

export interface FilterCheckboxProps {
  id: string;
  name: string;
  title: string;
  className?: string;
  handleCheck: any;
  checked?: boolean;
}

export const FilterCheckbox: React.FC<FilterCheckboxProps> = ({ id, name, title, className, handleCheck, checked }) => {
  return (
    <div>
      <input
        id={id}
        type="checkbox"
        name={name}
        className={className}
        onChange={handleCheck}
        checked={checked}
      />
      <label htmlFor={id}>{title}</label>
    </div>
  );
};
