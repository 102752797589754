import { Api } from "../../types/Api";
import { RootState } from "../rootReducer";

export const apiListSelector = (state: RootState): Api[] => {
  const filterValue = state.apis.filterValue.toLowerCase();
  if (!filterValue) {
    return state.apis.results;
  }
  return state.apis.results.filter((api: Api) =>
    api.name.toLowerCase().includes(filterValue)
  );
};

export const apisDeepProductSelector = (state: RootState): string[] =>
  state.apis.deepProduct;

export const apisFilterValueSelector = (state: RootState): string =>
  state.apis.filterValue;

export const selectedApiSelector = (state: RootState): Api | undefined =>
  state.apis.selected;

export const apisLoadingSelector = (state: RootState): boolean =>
  state.apis.loading;
