import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { withAuth } from "../../components/withAuth/withAuth";
import { ExceptionHistoryComponent } from "./ExceptionHistory.component";
import {
  apisExceptionLoadingSelector,
  selectedApiLatestExceptionSelector,
  selectedApisExceptionListSelector,
} from "../../store/apisException/selectors";
import {
  fetchApiLatestExceptionAction,
  fetchApisExceptionListAction,
} from "../../store/apisException/actions";

const ExceptionHistoryContainer = () => {
  const dispatch = useDispatch();
  interface Params {
    groupId: string;
    apiId: string;
  }

  const params = useParams<keyof Params>() as Params;

  const apiLatestException = useSelector(selectedApiLatestExceptionSelector);
  const apisException = useSelector(selectedApisExceptionListSelector);

  const apiLoading =
    useSelector(apisExceptionLoadingSelector) ||
    apiLatestException?.groupId !== params.groupId ||
    apiLatestException.assetId !== params.apiId;

  useEffect(() => {
    if (
      !(
        apiLatestException?.groupId === params.groupId &&
        apiLatestException.assetId === params.apiId
      )
    ) {
      dispatch(fetchApiLatestExceptionAction(params.groupId, params.apiId));
      dispatch(
        fetchApisExceptionListAction(params.groupId, params.apiId, false)
      );
    } else {
      dispatch(
        fetchApisExceptionListAction(params.groupId, params.apiId, false)
      );
    }
  }, [dispatch, params.groupId, params.apiId, apiLatestException]);

  const props = {
    apiLatestException,
    apisException,
    apiLoading,
  };

  return <ExceptionHistoryComponent {...props} />;
};

export default withAuth(ExceptionHistoryContainer);
