import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler } from "react-hook-form";
import { CreateReviewComponent } from "./CreateReview.component";
import { ApiReviewTogether } from "../../types/ApiReview";
import {
  apisLoadingSelector,
  apisReviewMessageSelector,
  apisReviewMessageLoadSelector,
  apiListReviewTypeSolutionSelector,
  apiListReviewTypeAPISpecSelector,
} from "../../store/apisReview/selectors";
import {
  authRoleMessageSelector,
  tokenSelector,
} from "../../store/auth/selectors";
import {
  listApisReviewTypeSolutionAction,
  listApisReviewTypeAPISpecAction,
  postAction,
} from "../../store/apisReview/actions";
import { withAuth } from "../../components/withAuth/withAuth";
import { postExceptionAction } from "../../store/apisException/actions";

const CreateReviewContainer: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(apisLoadingSelector);
  const reviewMessage = useSelector(apisReviewMessageSelector);
  const loadMessage = useSelector(apisReviewMessageLoadSelector);
  const [selectedReview, setSelectedReview] = useState("");
  const apisReviewTypeSolution = useSelector(apiListReviewTypeSolutionSelector);
  const apisReviewTypeApiSpec = useSelector(apiListReviewTypeAPISpecSelector);
  const deploymentTarget = [
    { value: "CH", name: "CloudHub" },
    { value: "RTF", name: "RTF" },
  ];
  const token = useSelector(tokenSelector);
  const isRoleAllowed = useSelector(authRoleMessageSelector);

  useEffect(() => {
    dispatch(listApisReviewTypeSolutionAction());
    dispatch(listApisReviewTypeAPISpecAction());
  }, [dispatch]);

  const formSubmitHandler: SubmitHandler<ApiReviewTogether> = (
    data: ApiReviewTogether,
    e: any
  ) => {
    e.preventDefault();
    //Transform Data from Object of Objects to Arrays
    const transformDataObjectToArray: any = Object.entries(data);
    //Filter Array to get the Selected Review
    const filterDataArrayToGetSelectedReview =
      transformDataObjectToArray.filter(
        (value: any) => value[0] === selectedReview
      );
    //Convert to Object
    const toObject: any = Object.fromEntries(
      filterDataArrayToGetSelectedReview
    );
    //Post the Values of the filled Selected Review
    if (selectedReview === "exception") {
      const toValues: any = Object.values(toObject);
      dispatch(postExceptionAction(toValues[0], token));
    } else {
      dispatch(postAction(toObject, token));
    }
  };

  const props = {
    apisReviewTypeSolution,
    apisReviewTypeApiSpec,
    deploymentTarget,
    selectedReview,
    setSelectedReview,
    loading,
    reviewMessage,
    loadMessage,
    isRoleAllowed,
    onSubmit: formSubmitHandler,
  };

  return <CreateReviewComponent {...props} />;
};

export default withAuth(CreateReviewContainer);
