import React from "react";
import cn from "classnames";
import { Api } from "../../types/Api";
import { SCORE_LEVEL } from "../../types/Compliance";
import { decimalToPercentage, getScoreLevel } from "../../utils/calcs";
import styles from "./scorePercentageBar.module.scss";

export interface ScorePercentageBarProps {
  api: Api;
}

const MAP_LEVELS_TO_CLASSES = {
  [SCORE_LEVEL.MEDIUM]: styles.medium,
  [SCORE_LEVEL.HIGH]: styles.high,
};

const ScorePercentageBar: React.FC<ScorePercentageBarProps> = ({ api }) => {
  const scoreClass = MAP_LEVELS_TO_CLASSES[getScoreLevel(api.compliance)];
  const scorePercentage = decimalToPercentage(api.compliance);
  return (
    <div className={styles.score}>
      <div className={styles.score_label}>{`${scorePercentage}%`}</div>
      <div className={styles.scorePercentage__bars}>
        <div className={styles.scorePercentage__bars_background}></div>
        <div
          className={cn(styles.scorePercentage__bars_value, scoreClass)}
          style={{ width: `${scorePercentage}%` }}
        ></div>
      </div>
    </div>
  );
};

export { ScorePercentageBar };
