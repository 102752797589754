import { REDIRECT_LOCATION_LABEL } from "../constants/authConfig";

const setLocationPath = () =>
  sessionStorage.setItem(REDIRECT_LOCATION_LABEL, window.location.pathname);

const getLocationPath = () => {
  const location = sessionStorage.getItem(REDIRECT_LOCATION_LABEL);
  sessionStorage.removeItem(REDIRECT_LOCATION_LABEL);
  return location;
};

export { setLocationPath, getLocationPath };
