import React, { useCallback } from "react";
import styles from "./apiList.module.scss";
import { PageHeader } from "../../components/PageHeader/PageHeader.component";
import { Api } from "../../types/Api";
import { APICard } from "../../components/APICard/APICard.component";
import { SearchBox } from "../../components/SearchBox/SearchBox.component";
import { Loader } from "../../components/Loader/Loader.component";
import {
  PillLabel,
  PillVariants,
} from "../../components/PillLabel/PillLabel.component";

export interface APIListComponentProps {
  apis: Api[];
  searchValue: string;
  onSearch: Function;
  loading: boolean;
}

export const APIListComponent: React.FC<APIListComponentProps> = ({
  apis,
  searchValue,
  onSearch,
  loading,
}) => {
  const handleSearch = useCallback(
    (value: string) => {
      onSearch(value);
    },
    [onSearch]
  );

  return (
    <div className={styles.apiList__wrapper}>
      <div className={styles.apiList__header}>
        <PageHeader
          title="API's"
          subtitle={`API's available: ${apis.length}`}
        />
        <SearchBox onChange={handleSearch} value={searchValue} />
      </div>

      {loading && <Loader />}

      <div className={styles.apiList__messages}>
        {!apis.length && !loading && (
          <PillLabel
            variant={PillVariants.ERROR}
            text="We couldn't find any API based on your search!"
          />
        )}
      </div>

      {!loading && (
        <div className={styles.apiList__list}>
          {apis.map((api: Api) => (
            <APICard key={`${api.groupId}_${api.id}`} api={api} />
          ))}
        </div>
      )}
    </div>
  );
};
