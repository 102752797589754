import React from "react";
import cn from "classnames";
import styles from "./weightLabel.module.scss";
import Icon from "@mdi/react";
import { mdiWeight } from "@mdi/js";
import { decimalToPercentage } from "../../utils/calcs";

export interface WeightLabelProps {
  large?: boolean;
  weight: number;
}

export const WeightLabel: React.FC<WeightLabelProps> = ({ large, weight }) => (
  <div className={styles.weightLabel}>
    <Icon path={mdiWeight} title="Weight" size={large ? "1.5rem" : "1rem"} />
    <div
      className={cn(styles.weightLabel__percentage, {
        [styles.weightLabel__percentage_large]: large,
      })}
    >
      {`${decimalToPercentage(weight, 1)}%`}
    </div>
  </div>
);
