import produce from "immer";
import { ApiException, ApiExceptionStatus } from "../../types/ApiException";
import {
  ApiActionsTypes,
  API_ACTIONS,
  SetApisExceptionLoadingActionType,
  SetApisMessageActionType,
  SetSelectedApiLatestExceptionActionType,
  SetSelectedApisExceptionStatusListByIdActionType,
  SetSelectedApisExceptionListByIdActionType,
  SetListApisExceptionStatusActionType,
  SetListApisExceptionActionType,
} from "./actions";

export interface ApiExceptionState {
  resultsException: ApiException[];
  resultsExceptionStatus: ApiExceptionStatus[];
  selectedLatestException?: ApiException;
  selectedExceptionStatusListById: ApiExceptionStatus[];
  selectedExceptionListById: ApiException[];
  loading: boolean;
  exceptionMessage: string;
  loadMessage: string;
  statusUniqueValues: string[];
}

const initialState = {
  resultsException: [] as ApiException[],
  resultsExceptionStatus: [] as ApiExceptionStatus[],
  selectedExceptionStatusListById: [] as ApiExceptionStatus[],
  selectedExceptionListById: [] as ApiException[],
  loading: true,
  exceptionMessage: "",
  loadMessage: "",
  statusUniqueValues: [],
};

export const apiExceptionReducer = produce(
  (draftState: ApiExceptionState = initialState, action?: ApiActionsTypes) => {
    switch (action?.type) {
      case API_ACTIONS.SET_LOADING: {
        const safeAction = action as SetApisExceptionLoadingActionType;
        draftState.loading = safeAction.payload;
        return draftState;
      }

      case API_ACTIONS.SET_MESSAGE: {
        const safeAction = action as SetApisMessageActionType;
        draftState.exceptionMessage = safeAction.payload;
        return draftState;
      }

      case API_ACTIONS.SET_LIST_EXCEPTION: {
        const safeAction = action as SetListApisExceptionActionType;
        draftState.resultsException = safeAction.payload;
        draftState.loading = false;
        return draftState;
      }

      case API_ACTIONS.SET_LIST_EXCEPTION_STATUS: {
        const safeAction = action as SetListApisExceptionStatusActionType;
        draftState.resultsExceptionStatus = safeAction.payload;
        if (!draftState.statusUniqueValues.length) {
          const resultsCopy: ApiExceptionStatus[] = JSON.parse(
            JSON.stringify(safeAction.payload)
          );
          draftState.statusUniqueValues = Array.from(
            new Set(resultsCopy.map((apiException) => apiException.status).sort())
            );
          }
        draftState.loading = false;
        return draftState;
      }

      case API_ACTIONS.SET_SELECTED_LIST_EXCEPTION_STATUS_BY_ID: {
        const safeAction = action as SetSelectedApisExceptionStatusListByIdActionType;
        draftState.selectedExceptionStatusListById = safeAction.payload;
        draftState.loading = false;
        return draftState;
      }

      case API_ACTIONS.SET_SELECTED_LIST_EXCEPTION_BY_ID: {
        const safeAction = action as SetSelectedApisExceptionListByIdActionType;
        draftState.selectedExceptionListById = safeAction.payload;
        draftState.loading = false;
        return draftState;
      }

      case API_ACTIONS.SET_SELECTED_LATEST_EXCEPTION: {
        const safeAction = action as SetSelectedApiLatestExceptionActionType;
        draftState.selectedLatestException = safeAction.payload;
        draftState.loading = false;
        return draftState;
      } 
      
      default: {
        return draftState;
      }
    }
  }
);
