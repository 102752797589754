import { AccountInfo } from "@azure/msal-browser";

export enum AUTH_ACTIONS {
  INIT = "##AUTH/INIT",
  UNAUTH = "##AUTH/UNAUTHENTICATED",
  LOGIN = "##AUTH/LOGIN",
  SET_TOKEN = "##AUTH/SET_TOKEN,",
  SET_ACCOUNT_INFO = "##AUTH/SET_ACCOUNT",
  FETCH_OWNER_ROLE = "##AUTH/FETCH_OWNER_ROLE",
  SET_MESSAGE_ROLE = "##AUTH/SET_MESSAGE_ROLE",
}

export interface AuthMissingAuthActionType {
  type: AUTH_ACTIONS.UNAUTH;
}

export interface AuthInitActionType {
  type: AUTH_ACTIONS.INIT;
}

export interface AuthLoginActionType {
  type: AUTH_ACTIONS.LOGIN;
}

export interface AuthSetTokenActionType {
  type: AUTH_ACTIONS.SET_TOKEN;
  payload: string;
}

export interface AuthSetAccountInfoActionType {
  type: AUTH_ACTIONS.SET_ACCOUNT_INFO;
  payload: AccountInfo;
}

export interface AuthFetchOwnerRoleActionType {
  type: AUTH_ACTIONS.FETCH_OWNER_ROLE;
  payload: string;
}

export interface AuthSetRoleMessageActionType {
  type: AUTH_ACTIONS.SET_MESSAGE_ROLE;
  payload: string;
}

export const authInitAction = (): AuthInitActionType => ({
  type: AUTH_ACTIONS.INIT,
});

export const authLoginAction = (): AuthLoginActionType => ({
  type: AUTH_ACTIONS.LOGIN,
});

export const authSetTokenAction = (token: string): AuthSetTokenActionType => ({
  type: AUTH_ACTIONS.SET_TOKEN,
  payload: token,
});

export const authSetAccountInfoAction = (
  accountInfo: AccountInfo
): AuthSetAccountInfoActionType => ({
  type: AUTH_ACTIONS.SET_ACCOUNT_INFO,
  payload: accountInfo,
});

export const authFetchRoleAction = (token: string): AuthFetchOwnerRoleActionType => ({
  type: AUTH_ACTIONS.FETCH_OWNER_ROLE,
  payload: token
});

export const authSetRoleMessageAction = (
  message: string
): AuthSetRoleMessageActionType => ({
  type: AUTH_ACTIONS.SET_MESSAGE_ROLE,
  payload: message,
});

export const authMissingAuthAction = (): AuthMissingAuthActionType => ({
  type: AUTH_ACTIONS.UNAUTH,
});

export type AuthActionsTypes =
  | AuthInitActionType
  | AuthLoginActionType
  | AuthSetTokenActionType
  | AuthSetAccountInfoActionType
  | AuthFetchOwnerRoleActionType
  | AuthSetRoleMessageActionType
  | AuthMissingAuthActionType;
