import produce from "immer";
import { ApiReview } from "../../types/ApiReview";
import {
  ApiActionsTypes,
  API_ACTIONS,
  SetApisReviewLoadingActionType,
  SetApisMessageActionType,
  SetApisMessageLoadActionType,
  SetListApisReviewByIdActionType,
  SetListApisReviewActionType,
  SetListApisReviewTypeSolutionActionType,
  SetListApisReviewTypeAPISpecActionType,
  SetSelectedApiLatestReviewActionType,
} from "./actions";

export interface ApiReviewState {
  resultsReview: ApiReview[];
  resultsReviewTypeSolution: ApiReview[];
  resultsReviewTypeAPISpec: ApiReview[];
  selectedLatestReview?: ApiReview;
  selectedReviewListById: ApiReview[];
  loading: boolean;
  reviewMessage: string;
  loadMessage: string;
}

const initialState = {
  resultsReview: [] as ApiReview[],
  resultsReviewTypeSolution: [] as ApiReview[],
  resultsReviewTypeAPISpec: [] as ApiReview[],
  selectedReviewListById: [] as ApiReview[],
  loading: true,
  reviewMessage: "",
  loadMessage: "",
};

export const apiReviewReducer = produce(
  (draftState: ApiReviewState = initialState, action?: ApiActionsTypes) => {
    switch (action?.type) {
      case API_ACTIONS.SET_LOADING: {
        const safeAction = action as SetApisReviewLoadingActionType;
        draftState.loading = safeAction.payload;
        return draftState;
      }

      case API_ACTIONS.SET_MESSAGE: {
        const safeAction = action as SetApisMessageActionType;
        draftState.reviewMessage = safeAction.payload;
        return draftState;
      }

      case API_ACTIONS.SET_MESSAGE_LOAD: {
        const safeAction = action as SetApisMessageLoadActionType;
        draftState.loadMessage = safeAction.payload;
        return draftState;
      }

      case API_ACTIONS.SET_LIST_REVIEW: {
        const safeAction = action as SetListApisReviewActionType;
        draftState.resultsReview = safeAction.payload;
        draftState.loading = false;
        return draftState;
      }
      
      case API_ACTIONS.SET_LIST_REVIEW_SOLUTION: {
        const safeAction = action as SetListApisReviewTypeSolutionActionType;
        draftState.resultsReviewTypeSolution = safeAction.payload;
        draftState.loading = false;
        return draftState;
      }

      case API_ACTIONS.SET_LIST_REVIEW_APISPEC: {
        const safeAction = action as SetListApisReviewTypeAPISpecActionType;
        draftState.resultsReviewTypeAPISpec = safeAction.payload;
        draftState.loading = false;
        return draftState;
      }

      case API_ACTIONS.SET_LIST_REVIEW_BY_ID: {
        const safeAction = action as SetListApisReviewByIdActionType;
        draftState.selectedReviewListById = safeAction.payload;
        draftState.loading = false;
        return draftState;
      }

      case API_ACTIONS.SET_SELECTED_LATEST_REVIEW: {
        const safeAction = action as SetSelectedApiLatestReviewActionType;
        draftState.selectedLatestReview = safeAction.payload;
        draftState.loading = false;
        return draftState;
      }
      
      default: {
        return draftState;
      }
    }
  }
);
