import { AccountInfo } from "@azure/msal-browser";
import produce from "immer";
import {
  AuthActionsTypes,
  AuthSetAccountInfoActionType,
  AuthSetTokenActionType,
  AuthSetRoleMessageActionType,
  AUTH_ACTIONS,
} from "./actions";

export interface AuthState {
  loading: boolean;
  isAuthenticated: boolean;
  accountInfo?: AccountInfo;
  token: string;
  roleMessage: string;
}

const initialState: AuthState = {
  token: "",
  loading: true,
  isAuthenticated: false,
  roleMessage: "",
};

export const authReducer = produce(
  (draftState: AuthState = initialState, action: AuthActionsTypes) => {
    switch (action.type) {
      case AUTH_ACTIONS.SET_TOKEN: {
        const safeAction = action as AuthSetTokenActionType;
        draftState.token = safeAction.payload;
        draftState.isAuthenticated = true;
        draftState.loading = false;
        return draftState;
      }

      case AUTH_ACTIONS.SET_ACCOUNT_INFO: {
        const safeAction = action as AuthSetAccountInfoActionType;
        draftState.accountInfo = safeAction.payload;
        draftState.isAuthenticated = true;
        draftState.loading = false;
        return draftState;
      }
      
      case AUTH_ACTIONS.SET_MESSAGE_ROLE: {
        const safeAction = action as AuthSetRoleMessageActionType;
        draftState.roleMessage = safeAction.payload;
        draftState.isAuthenticated = true;
        draftState.loading = false;
        return draftState;
      }

      case AUTH_ACTIONS.UNAUTH: {
        draftState.loading = false;
        draftState.isAuthenticated = false;
        return draftState;
      }

      default:
        return draftState;
    }
  }
);
