import React, { useCallback } from "react";
import { Criteria } from "../../types/Compliance";
import { CollapsibleContent } from "../CollapsibleContent/CollapsibleContent.component";
import styles from "./criteriaListItem.module.scss";
import { ValidationListItem } from "../ValidationListItem/ValidationListItem.component";
import { WeightLabel } from "../WeightLabel/WeightLabel.component";
import { CompletionPercentage } from "../CompletionPercentage/CompletionPercentage.component";
import { useCollapsable } from "../../hooks/useCollapsable";

export interface CriteriaListItemProps {
  criteria: Criteria;
}

export const CriteriaListItem: React.FC<CriteriaListItemProps> = ({
  criteria,
}) => {
  const [collapsed, toggleCollapsed] = useCollapsable();

  const handleClick = useCallback(() => {
    toggleCollapsed();
  }, [toggleCollapsed]);

  return (
    <div className={styles.criteria__listItem}>
      <div className={styles.criteria__header}>
        <div className={styles.criteria__header_left}>
          <div className={styles.criteria__label}>{criteria.name}</div>
          <div className={styles.criteria__weight}>
            <WeightLabel large weight={criteria.weight} />
          </div>
        </div>
        <div className={styles.criteria__header_center}>
          {criteria.description}
        </div>
        <div className={styles.criteria__header_right}>
          <CompletionPercentage
            value={criteria.value}
            onClickExpand={handleClick}
            collapsed={collapsed}
          />
        </div>
      </div>
      <div className={styles.criteria__collapsible}>
        <CollapsibleContent collapsed={collapsed}>
          <div className={styles.criteria__validations_wrapper}>
            {criteria.validations.map((validation) => (
              <ValidationListItem
                key={validation.name}
                validation={validation}
              />
            ))}
          </div>
        </CollapsibleContent>
      </div>
    </div>
  );
};
