import { call, put, takeEvery } from "redux-saga/effects";
import {
  COMPLIANCE_ACTIONS,
  FetchApiReportActionType,
  setComplianceLoadingAction,
  setComplianceReportAction,
} from "./actions";

import { Compliance } from "../../types/Compliance";
import { getApiReport } from "./requests";

export function* fetchApiReportSaga({
  payload: { groupId, apiId },
}: FetchApiReportActionType) {
  yield put(setComplianceLoadingAction(true));
  const result: Compliance = yield call(getApiReport, groupId, apiId);
  yield put(setComplianceReportAction(result));
}

function* complianceSaga() {
  yield takeEvery(COMPLIANCE_ACTIONS.FETCH_API_REPORT, fetchApiReportSaga);
}

export default complianceSaga;
