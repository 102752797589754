import React from "react";
import styles from "./filterDropdown.module.scss";

export interface FilterDropdownProps {
  nameDropdown: string;
  handleDropdown: React.ChangeEventHandler<HTMLSelectElement>;
  dropdownMap: string[];
  initialDropdown: string;
  selectedDropdown: string;
}

export const FilterDropdown: React.FC<FilterDropdownProps> = ({
  nameDropdown,
  handleDropdown,
  dropdownMap,
  initialDropdown,
  selectedDropdown
}) => {

  return (
    <>
      <select
        name={nameDropdown}
        onChange={handleDropdown}
        className={styles.filterDropdown__wrapper}
        data-testid="select-element-test-id"
      >
        <option hidden>{initialDropdown || selectedDropdown}</option>
        {dropdownMap.map((dropdownItem) => (
          <option key={dropdownItem} value={dropdownItem} className={styles.filterDropdown__option}>
            {dropdownItem}
          </option>
        ))}
      </select>
    </>
  );
};
