import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { withAuth } from "../../components/withAuth/withAuth";
import { fetchSystemAction } from "../../store/systems/actions";
import {
  selectedSystemSelector,
  systemsLoadingSelector,
} from "../../store/systems/selectors";
import { SystemDetailsComponent } from "./SystemDetails.component";

const SystemDetailsContainer = () => {
  const dispatch = useDispatch();

  interface Params {
    systemCode: string;
  }

  const params = useParams<keyof Params>() as Params;

  const system = useSelector(selectedSystemSelector);
  const loading = useSelector(systemsLoadingSelector);

  const systemNotFound = !!system && Object.keys(system).length === 0;

  const systemLoading =
    (loading || system?.systemCode !== params.systemCode) && !systemNotFound;

  useEffect(() => {
    if (!!params.systemCode && params.systemCode !== system?.systemCode && !systemNotFound) {
      dispatch(fetchSystemAction(params.systemCode));
    }
  }, [dispatch, params.systemCode, system, systemNotFound]);

  const props = {
    system,
    systemLoading,
  };

  return <SystemDetailsComponent {...props} />;
};

export default withAuth(SystemDetailsContainer);
