import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { withAuth } from "../../components/withAuth/withAuth";
import {
  apisLoadingSelector,
  selectedApiLatestReviewsSelector,
  selectedApiReviewsListSelector,
} from "../../store/apisReview/selectors";
import {
  fetchApiLatestReviewAction,
  fetchApiReviewListAction,
} from "../../store/apisReview/actions";
import { ReviewHistoryComponent } from "./ReviewHistory.component";

const ReviewHistoryContainer = () => {
  const dispatch = useDispatch();
  interface Params {
    groupId: string;
    apiId: string;
  }

  const params = useParams<keyof Params>() as Params;

  const apiLatestReview = useSelector(selectedApiLatestReviewsSelector);
  const apisReview = useSelector(selectedApiReviewsListSelector);

  const apiLoading =
    useSelector(apisLoadingSelector) ||
    apiLatestReview?.review.solutionDesign.groupId !== params.groupId ||
    apiLatestReview.apiId !== params.apiId;

  useEffect(() => {
    if (
      !(
        apiLatestReview?.review.solutionDesign.groupId === params.groupId &&
        apiLatestReview.apiId === params.apiId
      )
    ) {
      dispatch(fetchApiLatestReviewAction(params.groupId, params.apiId));
      dispatch(fetchApiReviewListAction(params.groupId, params.apiId));
    } else {
      dispatch(fetchApiReviewListAction(params.groupId, params.apiId));
    }
  }, [dispatch, params.groupId, params.apiId, apiLatestReview]);

  const props = {
    apiLatestReview,
    apisReview,
    apiLoading,
  };

  return <ReviewHistoryComponent {...props} />;
};

export default withAuth(ReviewHistoryContainer);
