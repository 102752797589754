import { call, put, select, takeEvery } from "redux-saga/effects";
import { ApiException, ApiExceptionStatus } from "../../types/ApiException";
import { PostMessage } from "../../constants/labels";
import { tokenSelector } from "../../store/auth/selectors";
import {
  API_ACTIONS,
  FetchApisExceptionListByIdActionType,
  FetchApiLatestExceptionActionType,
  setApisExceptionLoadingAction,
  setApisMessageAction,
  setSelectedApiLatestExceptionAction,
  setSelectedApisExceptionListByIdAction,
  PostExceptionActionType,
  setListApisExceptionStatusAction,
  FetchApisExceptionStatusListByIdActionType,
  setSelectedApisExceptionStatusListByIdAction,
  setListApisExceptionAction,
} from "./actions";
import {
  getApisExceptionById,
  getApiLatestException,
  postApisException,
  getApisExceptionStatus,
  getApisExceptionStatusById,
  getApisExceptions,
} from "./requests";

export function* listApisExceptionSaga() {
  try {
    yield put(setApisExceptionLoadingAction(true));
    const result: ApiException[] = yield call(getApisExceptions);
    yield put(setListApisExceptionAction(result));
  } catch (e) {
    yield put(setApisExceptionLoadingAction(false));
  }
}

export function* listApisExceptionStatusSaga() {
  try {
    yield put(setApisExceptionLoadingAction(true));
    const result: ApiExceptionStatus[] = yield call(getApisExceptionStatus);
    yield put(setListApisExceptionStatusAction(result));
  } catch (e) {
    yield put(setApisExceptionLoadingAction(false));
  }
}

export function* postExceptionSaga({ payload: { token, data } }: PostExceptionActionType) {
  try {
    while (token === undefined || token === "") {
      token = yield select(tokenSelector);
    }
    const result: ApiException = yield call(postApisException, token, data);
    yield put(setApisMessageAction(PostMessage.successfulSubmitted));
    return result;
  } catch (e) {
    yield put(setApisMessageAction(PostMessage.errorSubmitted));
  }
}

export function* fetchApisExceptionStatusListByIdSaga({
  payload: { groupId, apiId },
}: FetchApisExceptionStatusListByIdActionType) {
  yield put(setApisExceptionLoadingAction(true));
  const result: ApiExceptionStatus[] = yield call(getApisExceptionStatusById, groupId, apiId);
  yield put(setSelectedApisExceptionStatusListByIdAction(result));
}

export function* fetchApisExceptionListByIdSaga({
  payload: { groupId, apiId, latest },
}: FetchApisExceptionListByIdActionType) {
  yield put(setApisExceptionLoadingAction(true));
  const result: ApiException[] = yield call(getApisExceptionById, groupId, apiId, latest);
  yield put(setSelectedApisExceptionListByIdAction(result));
}

export function* fetchApiLatestExceptionSaga({
  payload: { groupId, apiId },
}: FetchApiLatestExceptionActionType) {
  yield put(setApisExceptionLoadingAction(true));
  const result: ApiException = yield call(getApiLatestException, groupId, apiId);
  yield put(setSelectedApiLatestExceptionAction(result));
}

export function* apisExceptionSaga() {
  yield takeEvery(API_ACTIONS.LIST_EXCEPTION, listApisExceptionSaga);
  yield takeEvery(API_ACTIONS.LIST_EXCEPTION_STATUS, listApisExceptionStatusSaga);
  yield takeEvery(API_ACTIONS.FETCH_LIST_EXCEPTION_STATUS_BY_ID, fetchApisExceptionStatusListByIdSaga);
  yield takeEvery(API_ACTIONS.FETCH_LIST_EXCEPTION_BY_ID, fetchApisExceptionListByIdSaga);
  yield takeEvery(API_ACTIONS.FETCH_ONE_LATEST_EXCEPTION, fetchApiLatestExceptionSaga);
  yield takeEvery(API_ACTIONS.POST_EXCEPTION, postExceptionSaga);
}

export default apisExceptionSaga;
