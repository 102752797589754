import React, { useEffect } from "react";
import { ScoreDetailsComponent } from "./ScoreDetails.component";
import { useDispatch, useSelector } from "react-redux";
import { fetchApiReportAction } from "../../store/compliance/actions";
import {
  complianceLoadingSelector,
  complianceSelector,
} from "../../store/compliance/selectors";
import { useQuery } from "../../hooks/useQuery";
import { fetchApiAction } from "../../store/apis/actions";
import {
  apisLoadingSelector,
  selectedApiSelector,
} from "../../store/apis/selectors";
import { withAuth } from "../../components/withAuth/withAuth";

const ScoreDetailsContainer: React.FC = () => {
  const dispatch = useDispatch();

  const query = useQuery();
  const [groupId, apiId] = [
    query.get("groupid") || "",
    query.get("apiid") || "",
  ];

  const compliance = useSelector(complianceSelector);
  const api = useSelector(selectedApiSelector);
  const loadingCompliance = useSelector(complianceLoadingSelector);
  const loadingApi = useSelector(apisLoadingSelector);
  const loading = loadingCompliance || loadingApi;

  useEffect(() => {
    dispatch(fetchApiReportAction(groupId, apiId));
    dispatch(fetchApiAction(groupId, apiId));
  }, [dispatch, groupId, apiId]);

  const props = {
    compliance,
    api,
    loading,
  };

  return <ScoreDetailsComponent {...props} />;
};

export default withAuth(ScoreDetailsContainer);
