import produce from "immer";
import { System } from "../../types/System";

import {
  SystemActionsTypes,
  SYSTEMS_ACTIONS,
  SetSystemsFilterValueActionType,
  SetSystemsLoadingActionType,
  SetListSystemsActionType,
  SetSelectedSystemActionType,
  SetSuggestionSystemsActionType,
} from "./actions";

export interface SystemState {
  results: System[];
  selected?: System;
  loading: boolean;
  filterValue: string;
  suggestions: System[];
}

const initialState = {
  results: [] as System[],
  loading: true,
  filterValue: "",
  suggestions: [] as System[],
};

export const systemsReducer = produce(
  (draftState: SystemState = initialState, action?: SystemActionsTypes) => {
    switch (action?.type) {
      case SYSTEMS_ACTIONS.SET_LOADING: {
        const safeAction = action as SetSystemsLoadingActionType;
        draftState.loading = safeAction.payload;
        return draftState;
      }

      case SYSTEMS_ACTIONS.SET_FILTER_VALUE: {
        const safeAction = action as SetSystemsFilterValueActionType;
        draftState.filterValue = safeAction.payload;
        return draftState;
      }

      case SYSTEMS_ACTIONS.SET_LIST: {
        const safeAction = action as SetListSystemsActionType;
        draftState.results = safeAction.payload;
        draftState.loading = false;
        return draftState;
      }

      case SYSTEMS_ACTIONS.SET_SUGGESTIONS: {
        const safeAction = action as SetSuggestionSystemsActionType;
        draftState.suggestions = safeAction.payload;
        draftState.loading = false;
        return draftState;
      }

      case SYSTEMS_ACTIONS.SET_SELECTED: {
        const safeAction = action as SetSelectedSystemActionType;
        draftState.selected = safeAction.payload;
        draftState.loading = false;
        return draftState;
      }

      case SYSTEMS_ACTIONS.CLEAR_SELECTED: {
        delete draftState.selected;
        return draftState;
      }

      default: {
        return draftState;
      }
    }
  }
);
