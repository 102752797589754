export enum SCORE_LEVEL {
  MEDIUM,
  HIGH,
}

export enum COMPLIANCE_LEVEL_VALUES {
  MEDIUM = 0.7,
  HIGH = 1,
}

export enum STEP {
  FAILED = 0,
  PASSED = 1,
}

export interface Step {
  name: string;
  description: string;
  weight: number;
  status: STEP;
}

export interface Validation {
  name: string;
  description: string;
  weight: number;
  value: number;
  steps: Step[];
}

export interface Criteria {
  name: string;
  description: string;
  weight: number;
  value: number;
  validations: Validation[];
}

export interface Compliance {
  apiID: string;
  complianceLevel: number;
  url: string;
  criterias: Criteria[];
}
