import { Endpoints } from "../../constants/endpoints";
import { httpRequest, OAP_API_PARAMS } from "../../utils/request";
import { ApiException } from "../../types/ApiException";

export const getApisExceptions = async () =>
  await httpRequest(Endpoints.apisException(), OAP_API_PARAMS());

export const getApisExceptionStatus = async () =>
  await httpRequest(Endpoints.apisExceptionStatus(), OAP_API_PARAMS());

export const getApisExceptionById = async (
  groupId: string,
  apiId: string,
  latest: boolean
) =>
  await httpRequest(
    Endpoints.apisExceptionByGroupIdApiId(groupId, apiId, latest),
    OAP_API_PARAMS()
  );

export const getApiLatestException = async (groupId: string, apiId: string) =>
  await httpRequest(
    Endpoints.apiLatestException(groupId, apiId),
    OAP_API_PARAMS()
  );

export const getApisExceptionStatusById = async (
  groupId: string,
  apiId: string
) =>
  await httpRequest(
    Endpoints.apisExceptionStatusByGroupIDApiId(groupId, apiId),
    OAP_API_PARAMS()
  );

export const postApisException = async (token: string, data: ApiException) =>
  await httpRequest(
    Endpoints.apisException(),
    OAP_API_PARAMS("POST", token, data)
  );
