import { useDispatch, useSelector } from "react-redux";
import { authLoginAction } from "../store/auth/actions";
import {
  isAuthenticatedSelector,
  tokenSelector,
} from "../store/auth/selectors";

export const useAuth = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  if (!isAuthenticated) {
    dispatch(authLoginAction());
  }

  const token = useSelector(tokenSelector);

  return {
    isAuthenticated,
    token,
  };
};
