import { Endpoints } from "../../constants/endpoints";
import { httpRequest, OAP_API_PARAMS } from "../../utils/request";
import { ApiReviewTogether } from "../../types/ApiReview";

export const getApisReview = async () =>
  await httpRequest(Endpoints.apisReview(), OAP_API_PARAMS());

export const getApisReviewByReviewTypeSolution = async () =>
  await httpRequest(
    Endpoints.apisReviewByReviewTypeSolution(),
    OAP_API_PARAMS()
  );

export const getApisReviewByReviewTypeAPISpec = async () =>
  await httpRequest(
    Endpoints.apisReviewByReviewTypeAPISpec(),
    OAP_API_PARAMS()
  );

export const getApiLatestReviewById = async (groupId: string, apiId: string) =>
  await httpRequest(
    Endpoints.apiLatestReviewByGroupIdApiId(groupId, apiId),
    OAP_API_PARAMS()
  );

export const getApisReviewById = async (groupId: string, apiId: string) =>
  await httpRequest(
    Endpoints.apisReviewByGroupIdApiId(groupId, apiId),
    OAP_API_PARAMS()
  );

export const postApisReview = async (token: string, data: ApiReviewTogether) =>
  await httpRequest(
    Endpoints.apisReview(),
    OAP_API_PARAMS("POST", token, data)
  );