import React from "react";
import styles from "./suggestionIconLabel.module.scss";
import { mdiStar } from "@mdi/js";
import Icon from "@mdi/react";

export interface SuggestionIconLabelProps {
  withLabel?: boolean;
}

const SuggestionIconLabel: React.FC<SuggestionIconLabelProps> = ({
  withLabel = false,
}) => (
  <div className={styles.wrapper}>
    <Icon path={mdiStar} title={"OAP Suggestion"} size={"1.5rem"} />
    {withLabel && <div className={styles.label}>OAP Suggestion</div>}
  </div>
);

export { SuggestionIconLabel };
