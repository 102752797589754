import React from "react";
import styles from "./scoreValue.module.scss";
import { Link } from "react-router-dom";
import { LINK_ENTITIES } from "../../constants/routes";
import { mdiInformation } from "@mdi/js";
import Icon from "@mdi/react";
import { Api } from "../../types/Api";
import { ScorePercentageBar } from "../ScorePercentageBar/ScorePercentageBar.component";

export interface ScoreValueProps {
  api: Api;
}

const ScoreValue: React.FC<ScoreValueProps> = ({ api }) => (
  <div className={styles.value__wrap}>
    <Link
      to={LINK_ENTITIES.report(api.id, api.groupId)}
      className={styles.link_fix}
    >
      <Icon path={mdiInformation} title={"View details"} size={"1.5rem"} />
    </Link>

    <ScorePercentageBar api={api} />
  </div>
);

export { ScoreValue };
