import React from "react";
import { Placeholder } from "../../constants/labels";
import styles from "./detailsField.module.scss";

export interface DetailsFieldProps {
  name: string | {};
  value: string | number | boolean | React.ReactElement;
  handler: string;
}

export const DetailsField: React.FC<DetailsFieldProps> = ({
  handler,
  name,
  value,
}) => (
  <div className={styles.field__wrapper}>
    <div className={styles.field__name}>{name}</div>
    <div className={styles.field__value} title={`${value}`}>
      {value || Placeholder.NotAvailable}
    </div>
  </div>
);
