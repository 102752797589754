import React, { useCallback } from "react";
import { ApiExceptionStatus } from "../../types/ApiException";
import styles from "./detailsExceptionStatusField.module.scss";
import { DetailsField } from "../DetailsField/DetailsField.component";
import {
  StatusValueLabels,
  APIExceptionStatusLabels,
  APIExceptionStatusSizingLabels,
} from "../../constants/labels";

const exceptionStatusFilterArray = [
  "assetId",
  "assetVersion",
  "status",
  "environment",
  "exception",
];

const filteredExceptionStatusDetails = (
  detailsLabelArray: string[]
): string[] =>
  detailsLabelArray.filter((key: string) =>
    exceptionStatusFilterArray.includes(key)
  );

const exceptionStatusApprovedSizingFilterArray = [
  "workerSize",
  "workerNumber",
  "replicas",
  "cpuLimit",
  "cpuReserved",
  "memory",
];

const filteredExceptionStatusApprovedSizingDetails = (
  detailsLabelArray: string[]
): string[] =>
  detailsLabelArray.filter((key: string) =>
    exceptionStatusApprovedSizingFilterArray.includes(key)
  );

const filteredExceptionStatusActualSizingDetails = (
  detailsLabelArray: string[]
): string[] =>
  detailsLabelArray.filter((key: string) =>
    exceptionStatusApprovedSizingFilterArray.includes(key)
  );

export interface DetailsExceptionStatusFieldProps {
  apisExceptionStatus?: ApiExceptionStatus;
}

export const DetailsExceptionStatusField: React.FC<
  DetailsExceptionStatusFieldProps
> = ({ apisExceptionStatus }) => {
  const exceptionStatusDetailsKeys: string[] = filteredExceptionStatusDetails(
    Object.keys(apisExceptionStatus || {})
  );
  const exceptionStatusApprovedSizingDetailsKeys: string[] =
    filteredExceptionStatusApprovedSizingDetails(
      Object.keys(apisExceptionStatus!.approvedSizing || {})
    );
  const exceptionStatusActualSizingDetailsKeys: string[] =
    filteredExceptionStatusActualSizingDetails(
      Object.keys(apisExceptionStatus!.actualSizing || {})
    );

  const formatApiValues = useCallback(
    (name, value) => {
      if (!apisExceptionStatus) {
        return value;
      }
      const toApprovedFormat = (exceptionStatus: boolean): string => {
        switch (exceptionStatus) {
          case true:
            return StatusValueLabels.True;
          default:
            return StatusValueLabels.False;
        }
      };
      const handlers: { [key: string]: (v: any) => any } = {
        exception: toApprovedFormat,
      };
      return handlers[name] ? handlers[name](value) : value;
    },
    [apisExceptionStatus]
  );

  return (
    <div className={styles.exception_wrapper}>
      <div className={styles.wrapper}>
        <div className={styles.wrapper_details}>
          {!!apisExceptionStatus &&
            (
              exceptionStatusDetailsKeys as Array<
                keyof typeof apisExceptionStatus
              >
            ).map((apiExceptionkey) => (
              <>
                <DetailsField
                  key={apiExceptionkey}
                  handler={apiExceptionkey}
                  name={APIExceptionStatusLabels[apiExceptionkey]}
                  value={formatApiValues(
                    apiExceptionkey,
                    apisExceptionStatus[apiExceptionkey]
                  )}
                />
              </>
            ))}
        </div>
        <div className={styles.sizing}>
          <div className={styles.approved_sizing}>
            <div className={styles.sizing_title}>Approved Sizing</div>
            <div>
              {!!apisExceptionStatus &&
                (
                  exceptionStatusApprovedSizingDetailsKeys as Array<
                    keyof typeof apisExceptionStatus.approvedSizing
                  >
                ).map((apiExceptionkey) => (
                  <>
                    <DetailsField
                      key={apiExceptionkey}
                      handler={apiExceptionkey}
                      name={APIExceptionStatusSizingLabels[apiExceptionkey]}
                      value={formatApiValues(
                        apiExceptionkey,
                        apisExceptionStatus.approvedSizing![apiExceptionkey]
                      )}
                    />
                  </>
                ))}
            </div>
          </div>
          <div className={styles.actual_sizing}>
            <div className={styles.sizing_title}>Actual Sizing</div>
            <div>
              {!!apisExceptionStatus &&
                (
                  exceptionStatusActualSizingDetailsKeys as Array<
                    keyof typeof apisExceptionStatus.actualSizing
                  >
                ).map((apiExceptionkey) => (
                  <DetailsField
                    key={apiExceptionkey + apisExceptionStatus.uniqueId}
                    handler={apiExceptionkey}
                    name={APIExceptionStatusSizingLabels[apiExceptionkey]}
                    value={formatApiValues(
                      apiExceptionkey,
                      apisExceptionStatus.actualSizing![apiExceptionkey]
                    )}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
