import React, { useEffect } from "react";
import { APIReviewDetailsComponent } from "./APIReviewDetails.component";
import { useDispatch, useSelector } from "react-redux";
import { selectedApiSelector } from "../../store/apis/selectors";
import {
  apisLoadingSelector,
  selectedApiLatestReviewsSelector,
} from "../../store/apisReview/selectors";
import {
  apisExceptionLoadingSelector,
  selectedApiLatestExceptionSelector,
  selectedApisExceptionStatusListSelector,
} from "../../store/apisException/selectors";
import {
  fetchApiLatestReviewAction,
  setApisLoadingAction,
} from "../../store/apisReview/actions";
import {
  fetchApiLatestExceptionAction,
  fetchApisExceptionStatusByIdListAction,
  setApisExceptionLoadingAction,
} from "../../store/apisException/actions";
import { useParams } from "react-router";
import { withAuth } from "../../components/withAuth/withAuth";

const APIDetailsContainer: React.FC = () => {
  const dispatch = useDispatch();

  interface Params {
    groupId: string;
    apiId: string;
  }

  const params = useParams<keyof Params>() as Params;

  const api = useSelector(selectedApiSelector);
  const apiLatestReview = useSelector(selectedApiLatestReviewsSelector);
  const apiLatestException = useSelector(selectedApiLatestExceptionSelector);
  const apisExceptionStatus = useSelector(
    selectedApisExceptionStatusListSelector
  );

  const apiLoading =
    useSelector(apisLoadingSelector) ||
    api?.groupId !== params.groupId ||
    api?.id !== params.apiId;

  const apiExceptionLoading = useSelector(apisExceptionLoadingSelector);

  useEffect(() => {
    if (!(api?.groupId === params.groupId && api.id === params.apiId)) {
      dispatch(fetchApiLatestReviewAction(params.groupId, params.apiId));
      dispatch(fetchApiLatestExceptionAction(params.groupId, params.apiId));
      dispatch(
        fetchApisExceptionStatusByIdListAction(params.groupId, params.apiId)
      );
      dispatch(setApisLoadingAction(true));
      dispatch(setApisExceptionLoadingAction(true));
    } else {
      dispatch(setApisLoadingAction(false));
    }
  }, [dispatch, params.groupId, params.apiId, api]);

  const props = {
    api,
    apiLatestReview,
    apiLatestException,
    apisExceptionStatus,
    apiLoading,
    apiExceptionLoading,
  };

  return <APIReviewDetailsComponent {...props} />;
};

export default withAuth(APIDetailsContainer);
