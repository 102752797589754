import React, { useEffect, useState } from "react";
import { ApiException } from "../../types/ApiException";
import { Loader } from "../../components/Loader/Loader.component";
import { PageHeader } from "../../components/PageHeader/PageHeader.component";
import { PillMessages, Placeholder } from "../../constants/labels";
import styles from "./exceptionHistory.module.scss";
import { GoBackButton } from "../../components/GoBackButton/GoBackButton.component";
import { DetailsExceptionField } from "../../components/DetailsExceptionField/DetailsExceptionField.component";
import BorderedBox from "../../components/BorderedBox/BorderedBox.component";
import BoxTitle from "../../components/BoxTitle/BoxTitle.component";
import {
  PillLabel,
  PillVariants,
} from "../../components/PillLabel/PillLabel.component";
import { FilterCheckbox } from "../../components/FilterCheckbox/FilterCheckbox.component";

export interface ExceptionHistoryComponentProps {
  apiLatestException?: ApiException;
  apisException: ApiException[];
  apiLoading: boolean;
}

export const ExceptionHistoryComponent: React.FC<
  ExceptionHistoryComponentProps
> = ({ apiLatestException, apisException, apiLoading }) => {
  useEffect(() => {
    setApisExceptionState(apisException);
  }, [apisException]);

  const [apisExceptionState, setApisExceptionState] = useState(apisException);
  const [checked, setChecked] = useState(false);

  //Start Filter Approved
  const handleCheck = (checked: boolean) => {
    if (checked === true) {
      setChecked(true);
      const data = apisException.filter(
        (apiException) => apiException.approved === true
      );
      setApisExceptionState(data);
    } else {
      setChecked(false);
      setApisExceptionState(apisException);
    }
  };
  //End Filter Approved

  if (apiLoading) {
    return <Loader />;
  } else if (
    (!apiLatestException || !apisExceptionState.length) &&
    !apiLoading
  ) {
    <div>
      <PillLabel
        variant={PillVariants.ERROR}
        text={PillMessages.missingException}
      />
    </div>;
  }

  return (
    <div className={styles.history_main_wrapper}>
      <div className={styles.history_header}>
        <PageHeader
          bookmark="API Exceptions History"
          title={apiLatestException?.assetId || Placeholder.NotAvailable}
        />
        <GoBackButton />
      </div>

      <>
        <div className={styles.history_filter}>
          <FilterCheckbox
            id="1"
            title="Approved"
            name="approvedExceptionStatus"
            handleCheck={(e: any) => handleCheck(e.target.checked)}
          />
        </div>

        {checked === true && !apisExceptionState.length ? (
          <div>
            <PillLabel
              variant={PillVariants.ERROR}
              text={PillMessages.noApprovedFound}
            />
          </div>
        ) : (
          apisExceptionState?.map((apiExceptionItem, index) => (
            <BorderedBox>
              <BoxTitle title="Exception"></BoxTitle>
              <DetailsExceptionField
                apiException={apiExceptionItem}
                key={index}
              />
            </BorderedBox>
          ))
        )}
      </>
    </div>
  );
};
