import React, { useCallback } from "react";
import BorderedBox from "../../components/BorderedBox/BorderedBox.component";
import BoxTitle from "../../components/BoxTitle/BoxTitle.component";
import { DetailsField } from "../../components/DetailsField/DetailsField.component";
import { PageHeader } from "../../components/PageHeader/PageHeader.component";
import {
  PillLabel,
  PillVariants,
} from "../../components/PillLabel/PillLabel.component";
import {
  MergeAPILabels,
  PillMessages,
  Placeholder,
  StatusValueLabels,
} from "../../constants/labels";
import { Api } from "../../types/Api";
import { ApiReview } from "../../types/ApiReview";
import styles from "./apiReviewDetails.module.scss";
import Icon from "@mdi/react";
import { mdiEye, mdiDotsHorizontalCircle } from "@mdi/js";
import { Link } from "react-router-dom";
import { LINK_ENTITIES } from "../../constants/routes";
import { Loader } from "../../components/Loader/Loader.component";
import { DetailsReviewField } from "../../components/DetailsReviewField/DetailsReviewField.component";
import { GoBackButton } from "../../components/GoBackButton/GoBackButton.component";
import { ApiException, ApiExceptionStatus } from "../../types/ApiException";
import { DetailsExceptionField } from "../../components/DetailsExceptionField/DetailsExceptionField.component";

const detailsFilterArray = [
  "id",
  "name",
  "deepProduct",
  "owner",
  "approved",
  "creationDate",
];

const filteredApiDetails = (detailsLabelArray: string[]): string[] =>
  detailsLabelArray.filter((key: string) => detailsFilterArray.includes(key));

export interface APIReviewDetailsProps {
  api?: Api;
  apiLatestReview?: ApiReview;
  apiLatestException?: ApiException;
  apisExceptionStatus: ApiExceptionStatus[];
  apiLoading: boolean;
  apiExceptionLoading: boolean;
}

export const APIReviewDetailsComponent: React.FC<APIReviewDetailsProps> = ({
  api,
  apiLatestReview,
  apiLatestException,
  apisExceptionStatus,
  apiLoading,
  apiExceptionLoading,
}) => {
  const approved: any = apiLatestReview?.approved;
  const mergeApis = { ...api, approved };

  const apiDetailsKeys: string[] = filteredApiDetails(
    Object.keys(mergeApis || {})
  );

  const formatApiValues = useCallback(
    (name, value) => {
      if (!api) {
        return value;
      }
      const toApprovedFormat = (approvedStatus: boolean): string => {
        switch (approvedStatus) {
          case true:
            return StatusValueLabels.True;
          case false:
            return StatusValueLabels.False;
          default:
            return StatusValueLabels.ToBeReviewed;
        }
      };
      const handlers: { [key: string]: (v: any) => any } = {
        creationDate: (value: string) => new Date(value).toUTCString(),
        approved: toApprovedFormat,
        solutionUrl: (value: string) => (
          <a className={styles.apiDetails_Link} href={value} title={value}>
            {value.split("=")[1]}
          </a>
        ),
        qaReviewUrl: (value: string) => (
          <a className={styles.apiDetails_Link} href={value} title={value}>
            {value.split("=")[1]}
          </a>
        ),
        approvalDate: (value: string) => new Date(value).toUTCString(),
        lastUpdateDate: (value: string) => new Date(value).toUTCString(),
      };
      return handlers[name] ? handlers[name](value) : value;
    },
    [api]
  );

  if (apiLoading || apiExceptionLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.apiDetails__wrapper}>
      <div className={styles.apiDetails_header}>
        <PageHeader
          bookmark="API Review"
          title={api?.name || Placeholder.NotAvailable}
        />
        <GoBackButton />
      </div>

      <BorderedBox>
        <BoxTitle title="Details"></BoxTitle>
        <div className={styles.details_wrapper}>
          {!!mergeApis &&
            (apiDetailsKeys as Array<keyof typeof mergeApis>).map((apikey) => (
              <DetailsField
                key={apikey}
                handler={apikey}
                name={MergeAPILabels[apikey]}
                value={formatApiValues(apikey, mergeApis[apikey])}
              />
            ))}
        </div>
      </BorderedBox>

      <BorderedBox>
        <BoxTitle title="References"></BoxTitle>
        <div className={styles.details_references}>
          {api?.url && <a href={api?.url}>Exchange</a>}
          {apiLatestReview?.review.solutionDesign.solutionUrl && (
            <a href={apiLatestReview?.review.solutionDesign.solutionUrl}>
              Solution Design
            </a>
          )}
          {apiLatestReview?.review.code.qaReviewUrl && (
            <a href={apiLatestReview?.review.code.qaReviewUrl}>QA Review</a>
          )}
        </div>
      </BorderedBox>

      <BorderedBox>
        <div className={styles.details__title}>
          <BoxTitle title="Reviews"></BoxTitle>
          {!apiLatestReview && (
            <PillLabel
              variant={PillVariants.ERROR}
              text={PillMessages.missingReview}
            />
          )}

          {!!apiLatestReview && (
            <Link
              to={LINK_ENTITIES.reviewHistory(
                apiLatestReview?.apiId,
                apiLatestReview.review.solutionDesign.groupId
              )}
              className={styles.icon__link}
            >
              <Icon path={mdiEye} title={"View Review History"} size={"2rem"} />
            </Link>
          )}
        </div>

        {apiLatestReview === null || apiLatestReview ? "" : <Loader />}
        {!!apiLatestReview && (
          <DetailsReviewField apiLatestReview={apiLatestReview} />
        )}
      </BorderedBox>

      <BorderedBox>
        <div className={styles.details__title}>
          <div className={styles.details__title_eye}>
            <BoxTitle title="Exceptions"></BoxTitle>
            {!apiLatestException && (
              <PillLabel
                variant={PillVariants.ERROR}
                text={PillMessages.missingException}
              />
            )}

            {!!apiLatestException && (
              <Link
                to={LINK_ENTITIES.exceptionHistory(
                  apiLatestException?.assetId,
                  apiLatestException.groupId
                )}
                className={styles.icon__link}
              >
                <Icon
                  path={mdiEye}
                  title={"View Exceptions History"}
                  size={"2rem"}
                />
              </Link>
            )}
          </div>

          {!!api && !!apisExceptionStatus.length && (
            <Link
              to={LINK_ENTITIES.exceptionStatus(
                api?.id,
                api.groupId
              )}
              className={styles.icon__link}
            >
              <Icon
                path={mdiDotsHorizontalCircle}
                title={"View Exceptions Status"}
                size={"2rem"}
              />
            </Link>
          )}
        </div>

        {apiLatestException === null || apiLatestException ? "" : <Loader />}
        {!!apiLatestException && (
          <DetailsExceptionField apiException={apiLatestException} />
        )}
      </BorderedBox>
    </div>
  );
};
