import produce from "immer";
import { Api } from "../../types/Api";

import {
  ApiActionsTypes,
  SetApisFilterValueActionType,
  SetApisLoadingActionType,
  SetApiSystemCodeActionType,
  SetListApisActionType,
  SetSelectedApiActionType,
  API_ACTIONS,
} from "./actions";

export interface ApiState {
  results: Api[];
  selected?: Api;
  loading: boolean;
  filterValue: string;
  deepProduct: string[];
}

const initialState = {
  results: [] as Api[],
  loading: true,
  filterValue: "",
  deepProduct: [],
};

export const apiReducer = produce(
  (draftState: ApiState = initialState, action?: ApiActionsTypes) => {
    switch (action?.type) {
      case API_ACTIONS.SET_LOADING: {
        const safeAction = action as SetApisLoadingActionType;
        draftState.loading = safeAction.payload;
        return draftState;
      }

      case API_ACTIONS.SET_FILTER_VALUE: {
        const safeAction = action as SetApisFilterValueActionType;
        draftState.filterValue = safeAction.payload;
        return draftState;
      }

      case API_ACTIONS.SET_LIST: {
        const safeAction = action as SetListApisActionType;
        draftState.results = safeAction.payload;
        if (!draftState.deepProduct.length && !draftState.filterValue) {
          const resultsCopy: Api[] = JSON.parse(
            JSON.stringify(safeAction.payload)
          );
          draftState.deepProduct = Array.from(
            new Set(resultsCopy.map((api) => api.deepProduct).sort())
          );
        }
        draftState.loading = false;
        return draftState;
      }

      case API_ACTIONS.SET_SELECTED: {
        const safeAction = action as SetSelectedApiActionType;
        draftState.selected = safeAction.payload;
        draftState.loading = false;
        return draftState;
      }

      case API_ACTIONS.SET_SYSTEM_CODE: {
        const safeAction = action as SetApiSystemCodeActionType;
        if (draftState.selected) {
          draftState.selected.systemCode = safeAction.payload;
        }
        return draftState;
      }

      default: {
        return draftState;
      }
    }
  }
);
