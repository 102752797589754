import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { withAuth } from "../../components/withAuth/withAuth";
import { ExceptionStatusComponent } from "./ExceptionStatus.component";
import {
  fetchApisExceptionStatusByIdListAction,
} from "../../store/apisException/actions";
import { fetchApiAction } from "../../store/apis/actions";
import {
  apisExceptionLoadingSelector,
  selectedApisExceptionStatusListSelector,
} from "../../store/apisException/selectors";
import { selectedApiSelector } from "../../store/apis/selectors";

const ExceptionStatusContainer = () => {
  const dispatch = useDispatch();
  interface Params {
    groupId: string;
    apiId: string;
  }

  const params = useParams<keyof Params>() as Params;

  const api = useSelector(selectedApiSelector);
  const apisExceptionStatus = useSelector(
    selectedApisExceptionStatusListSelector
  );

  const apiLoading =
    useSelector(apisExceptionLoadingSelector) ||
    api?.groupId !== params.groupId ||
    api.id !== params.apiId;

  useEffect(() => {
    if (
      !(
        api?.groupId === params.groupId &&
        api.id === params.apiId
      )
    ) {
      dispatch(fetchApiAction(params.groupId, params.apiId));
      dispatch(
        fetchApisExceptionStatusByIdListAction(params.groupId, params.apiId)
      );
    }
  }, [dispatch, params.groupId, params.apiId, api]);

  const props = {
    api,
    apisExceptionStatus,
    apiLoading,
  };

  return <ExceptionStatusComponent {...props} />;
};

export default withAuth(ExceptionStatusContainer);
