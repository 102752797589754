import React from "react";
import cn from "classnames";
import styles from "./pillLabel.module.scss";

export enum PillVariants {
  INFO,
  WARNING,
  ERROR,
}

export interface PillLabelProps {
  text: string;
  variant?: PillVariants;
}

const PillLabel: React.FC<PillLabelProps> = ({
  text,
  variant = PillVariants.INFO,
}) => {
  return (
    <label
      className={cn(styles.pill__wrapper, {
        [styles.error]: variant === PillVariants.ERROR,
      })}
    >
      {text}
    </label>
  );
};

export { PillLabel };
