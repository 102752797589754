import React, { useEffect } from "react";
import { APIDetailsComponent } from "./APIDetails.component";
import { useDispatch, useSelector } from "react-redux";
import {
  apisLoadingSelector,
  selectedApiSelector,
} from "../../store/apis/selectors";
import { fetchApiAction } from "../../store/apis/actions";
import { useParams } from "react-router";
import {
  selectedSystemSelector,
  systemListSelector,
  systemsFilterValueSelector,
  systemsLoadingSelector,
  systemSuggestionsSelector,
} from "../../store/systems/selectors";
import {
  associateSystemAction,
  clearSelectedSystemAction,
  fetchSystemAction,
  getSystemsSuggestionsAction,
  listSystemsAction,
  setSystemsFilterValueAction,
  setSystemsLoadingAction,
} from "../../store/systems/actions";
import { Defaults } from "../../constants/defaults";
import { withAuth } from "../../components/withAuth/withAuth";

const APIDetailsContainer: React.FC = () => {
  const dispatch = useDispatch();

  interface Params {
    groupId: string;
    apiId: string;
  }

  const params = useParams<keyof Params>() as Params;

  const api = useSelector(selectedApiSelector);
  const system = useSelector(selectedSystemSelector);
  const systems = useSelector(systemListSelector);
  const systemsSuggestions = useSelector(systemSuggestionsSelector);
  const systemsFilter = useSelector(systemsFilterValueSelector);
  const apiLoading =
    useSelector(apisLoadingSelector) ||
    api?.groupId !== params.groupId ||
    api?.id !== params.apiId;
  const systemsLoading = useSelector(systemsLoadingSelector);
  const onSearch = (value: string) =>
    dispatch(setSystemsFilterValueAction(value));
  const setSystem = (groupId: string, apiId: string, systemCode: string) => {
    if (api?.assetVersion) {
      dispatch(
        associateSystemAction(groupId, apiId, api.assetVersion, systemCode)
      );
    }
  };

  useEffect(() => {
    if (!(api?.groupId === params.groupId && api.id === params.apiId)) {
      dispatch(fetchApiAction(params.groupId, params.apiId));
      dispatch(clearSelectedSystemAction());
      dispatch(setSystemsLoadingAction(true));
    } else {
      if (api?.systemCode !== Defaults.SystemNotDefined) {
        dispatch(fetchSystemAction(api?.systemCode));
      } else {
        dispatch(listSystemsAction());
        dispatch(getSystemsSuggestionsAction(params.groupId, params.apiId));
      }
    }
  }, [dispatch, params.groupId, params.apiId, api]);

  const props = {
    api,
    system,
    systems,
    systemsSuggestions,
    systemsFilter,
    apiLoading,
    systemsLoading,
    onSearch,
    setSystem,
  };

  return <APIDetailsComponent {...props} />;
};

export default withAuth(APIDetailsContainer);
