import { Endpoints } from "../../constants/endpoints";
import { httpRequest, OAP_API_PARAMS } from "../../utils/request";

export const getSystems = async () =>
  await httpRequest(Endpoints.systems(), OAP_API_PARAMS());
export const getSystemById = async (systemId: string) =>
  await httpRequest(Endpoints.systemById(systemId), OAP_API_PARAMS());
export const getSystemSuggestions = async (groupId: string, apiId: string) =>
  await httpRequest(
    Endpoints.systemSuggestions(groupId, apiId),
    OAP_API_PARAMS()
  );

export const associateSystem = async (
  groupId: string,
  apiId: string,
  version: string,
  systemCode: string
) =>
  await httpRequest(
    Endpoints.associateApiWithSystem(groupId, apiId, version),
    OAP_API_PARAMS("POST", "", { systemId: systemCode })
  );
