import React, { MouseEventHandler } from "react";
import { System } from "../../types/System";
import { CTAButton } from "../CTAButton/CTAButton.component";
import styles from "./systemListItem.module.scss";
import { SuggestionIconLabel } from "../SuggestionIconLabel/SuggestionIconLabel.component";
import { SystemLabels } from "../../constants/labels";

interface SystemValueColumnProps {
  label: string;
  value: string;
  isSuggestion?: boolean;
}

export interface SystemListItemProps {
  system: System;
  isSuggestion?: boolean;
  ctaAction?: MouseEventHandler;
}

const SystemValueColumn: React.FC<SystemValueColumnProps> = ({
  label,
  value,
  isSuggestion = false,
}) => (
  <div className={styles.systemItem__column}>
    <div className={styles.label}>{label}</div>
    <div className={styles.value} title={value}>
      {isSuggestion && <SuggestionIconLabel />}
      {value}
    </div>
  </div>
);

const SystemListItem: React.FC<SystemListItemProps> = ({
  system,
  isSuggestion = false,
  ctaAction,
}) => (
  <div className={styles.systemItem__wrapper}>
    <SystemValueColumn
      label={SystemLabels.name}
      value={system.name}
      isSuggestion={isSuggestion}
    />
    <SystemValueColumn
      label={SystemLabels.systemCode}
      value={system.systemCode}
    />
    <SystemValueColumn label={SystemLabels.platform} value={system.platform} />
    <div className={styles.systemItem__column_action}>
      <CTAButton onClick={ctaAction}>Select</CTAButton>
    </div>
  </div>
);

export { SystemListItem };
