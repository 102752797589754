import React, { useCallback, useState } from "react";
import styles from "./apiReviewList.module.scss";
import { PageHeader } from "../../components/PageHeader/PageHeader.component";
import { Api } from "../../types/Api";
import { ApiReview } from "../../types/ApiReview";
import { ApiException, ApiExceptionStatus } from "../../types/ApiException";
import { APICardStatus } from "../../components/APICardStatus/APICard.component";
import { SearchBox } from "../../components/SearchBox/SearchBox.component";
import { Loader } from "../../components/Loader/Loader.component";
import {
  PillLabel,
  PillVariants,
} from "../../components/PillLabel/PillLabel.component";
import { PillMessages } from "../../constants/labels";
import { FilterCheckbox } from "../../components/FilterCheckbox/FilterCheckbox.component";
import { FilterDropdown } from "../../components/FilterDropdown/FilterDropdown.component";

export interface APIReviewListComponentProps {
  apis: Api[];
  apisDeepProduct: string[];
  apisReview: ApiReview[];
  apisException: ApiException[];
  apisExceptionStatus: ApiExceptionStatus[];
  apisExceptionStatusUniqueValues: string[];
  searchValue: string;
  onSearch: Function;
  selectedEnv: string;
  setSelectedEnv: Function;
  selectedlayer: string;
  setSelectedLayer: Function;
  selectedDeepProduct: string;
  setSelectedDeepProduct: Function;
  selectedExceptionStatusUniqueValue: string;
  setSelectedExceptionStatusUniqueValue: Function;
  loading: boolean;
}

export const APIReviewListComponent: React.FC<APIReviewListComponentProps> = ({
  apis,
  apisDeepProduct,
  apisReview,
  apisException,
  apisExceptionStatus,
  apisExceptionStatusUniqueValues,
  searchValue,
  onSearch,
  selectedEnv,
  setSelectedEnv,
  selectedlayer,
  setSelectedLayer,
  selectedDeepProduct,
  setSelectedDeepProduct,
  selectedExceptionStatusUniqueValue,
  setSelectedExceptionStatusUniqueValue,
  loading,
}) => {
  const handleSearch = useCallback(
    (value: string) => {
      onSearch(value);
    },
    [onSearch]
  );

  let mergeApis = apis.map((apis) => ({
    ...apis,
    ...apisReview.find((apisReview) => apisReview.apiId === apis.id),
    ...apisExceptionStatus.find(
      (apiExceptionStatus) => apiExceptionStatus.assetId === apis.id
    ),
    apisException: apisException
      .filter((apiException) => apiException.assetId === apis.id)
      .pop(),
  }));

  //Start Filter
  const [filteredAproved, setfilteredAproved] = useState();
  const [filteredAprovedException, setfilteredAprovedException] = useState();

  //Start Filter Dropdown
  const layersName = ["All", "System", "Experience", "Process"];
  const envName = ["All", "DEV", "QAS", "PRD"];
  //End Filter Dropdown

  mergeApis = mergeApis.filter((apiReview) => {
    if (
      filteredAproved &&
      selectedExceptionStatusUniqueValue !== "" &&
      filteredAprovedException
    ) {
      return (
        apiReview.approved === true &&
        apiReview.status === selectedExceptionStatusUniqueValue &&
        apiReview.apisException?.approved === true
      );
    } else if (
      filteredAproved &&
      selectedExceptionStatusUniqueValue !== "" &&
      !filteredAprovedException
    ) {
      return (
        apiReview.approved === true &&
        apiReview.status === selectedExceptionStatusUniqueValue
      );
    } else if (
      selectedExceptionStatusUniqueValue !== "" &&
      filteredAprovedException &&
      !filteredAproved
    ) {
      return (
        apiReview.status === selectedExceptionStatusUniqueValue &&
        apiReview.apisException?.approved === true
      );
    } else if (
      filteredAprovedException &&
      filteredAproved &&
      selectedExceptionStatusUniqueValue === ""
    ) {
      return (
        apiReview.approved === true &&
        apiReview.apisException?.approved === true
      );
    } else if (
      filteredAproved &&
      selectedExceptionStatusUniqueValue === "" &&
      !filteredAprovedException
    ) {
      return apiReview.approved === true;
    } else if (
      selectedExceptionStatusUniqueValue !== "" &&
      !filteredAproved &&
      !filteredAprovedException
    ) {
      return apiReview.status === selectedExceptionStatusUniqueValue;
    } else if (
      filteredAprovedException &&
      !filteredAproved &&
      selectedExceptionStatusUniqueValue === ""
    ) {
      return apiReview.apisException?.approved === true;
    } else if (
      !filteredAproved &&
      selectedExceptionStatusUniqueValue === "" &&
      !filteredAprovedException
    ) {
      return mergeApis;
    }
    return mergeApis;
  });
  //End Filter

  return (
    <div className={styles.apiListV1__wrapper}>
      <div className={styles.apiList__header}>
        <PageHeader
          title="API Reviews"
          subtitle={`API's available: ${mergeApis.length}`}
        />
        <div className={styles.apiList__search}>
          <SearchBox onChange={handleSearch} value={searchValue} />
          <div className={styles.apiList__filters}>
            <div className={styles.apiList__filters_top}>
              <FilterCheckbox
                id="approvedException"
                title="Approved Exception"
                name="approvedException"
                handleCheck={(e: any) =>
                  setfilteredAprovedException(e.target.checked)
                }
              />
              <FilterDropdown
                nameDropdown="ExceptionStatus"
                handleDropdown={(e: any) =>
                  setSelectedExceptionStatusUniqueValue(
                    e.target.value === "All" ? "" : e.target.value
                  )
                }
                dropdownMap={["All", ...apisExceptionStatusUniqueValues]}
                initialDropdown={"Exception Status"}
                selectedDropdown={selectedExceptionStatusUniqueValue}
              />
            </div>

            <div className={styles.apiList__filters_bottom}>
              <FilterCheckbox
                id="approvedReviews"
                title="Approved Reviews"
                name="approvedReviews"
                handleCheck={(e: any) => setfilteredAproved(e.target.checked)}
              />
              <FilterDropdown
                nameDropdown="Layer"
                handleDropdown={(e: any) => setSelectedLayer(e.target.value)}
                dropdownMap={layersName}
                initialDropdown={"Layer"}
                selectedDropdown={selectedlayer}
              />
              <FilterDropdown
                nameDropdown="Env"
                handleDropdown={(e: any) => setSelectedEnv(e.target.value)}
                dropdownMap={envName}
                initialDropdown={"Environment"}
                selectedDropdown={selectedEnv}
              />
              <FilterDropdown
                nameDropdown="DeepProduct"
                handleDropdown={(e: any) =>
                  setSelectedDeepProduct(
                    e.target.value === "All" ? "" : e.target.value
                  )
                }
                dropdownMap={["All", ...apisDeepProduct]}
                initialDropdown={"Product"}
                selectedDropdown={selectedDeepProduct}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.apiList__messages}>
        {!mergeApis.length && !loading && (
          <PillLabel
            variant={PillVariants.ERROR}
            text={PillMessages.searchApis}
          />
        )}
      </div>

      {loading && <Loader />}

      {!loading && (
        <div className={styles.apiList__list}>
          {mergeApis.map((api) => (
            <APICardStatus
              key={`${api.groupId}_${api.id}`}
              api={api}
              apiReviewApproved={api.approved}
              apiExceptionStatus={api.status}
            />
          ))}
        </div>
      )}
    </div>
  );
};
