import React, { MouseEventHandler } from "react";
import cn from "classnames";
import styles from "./completionPercentage.module.scss";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiCircleSlice5 } from "@mdi/js";
import { decimalToPercentage } from "../../utils/calcs";

export interface CompletionPercentageProps {
  value: number;
  collapsed: boolean;
  onClickExpand: MouseEventHandler;
}

export const CompletionPercentage: React.FC<CompletionPercentageProps> = ({
  value,
  onClickExpand,
  collapsed,
}) => (
  <>
    <Icon path={mdiCircleSlice5} title={"Value"} size={"1.5rem"} />
    <div className={styles.completion__value}>{`${decimalToPercentage(
      value,
      1
    )}%`}</div>
    <div
      className={cn(styles.completion__expand, {
        [styles.completion__expand_rotate]: collapsed,
      })}
      onClick={onClickExpand}
    >
      <Icon path={mdiChevronDown} title={"Expand"} size={"1.5rem"} />
    </div>
  </>
);
