import { SCORE_LEVEL, COMPLIANCE_LEVEL_VALUES } from "../types/Compliance";

export const decimalToPercentage = (
  decimal: number,
  digits: number = 0
): string => parseFloat((decimal * 100).toFixed(digits)).toString();

export const getScoreLevel = (value: number): SCORE_LEVEL =>
  value < COMPLIANCE_LEVEL_VALUES.MEDIUM
    ? SCORE_LEVEL.MEDIUM
    : SCORE_LEVEL.HIGH;
