import { RootState } from "../rootReducer";

export const tokenSelector = (state: RootState): string =>
  state.auth.token;

export const authLoadingSelector = (state: RootState): boolean =>
  state.auth.loading;

export const isAuthenticatedSelector = (state: RootState): boolean =>
  state.auth.isAuthenticated;

export const authRoleMessageSelector = (state: RootState): string => {
  return state.auth.roleMessage;
};
