import React from "react";
import { withAuth } from "../../components/withAuth/withAuth";
import styles from "./home.module.scss";

export interface HomeComponentProps {}

const HomeComponent: React.FC<HomeComponentProps> = () => {
  return (
    <div className={styles.home__wrapper}>
      <iframe
        className={styles.power_bi__iframe}
        title="PowerBI Report"
        src={process.env.REACT_APP_POWER_BI_URL}
        frameBorder="0"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
};

export default withAuth(HomeComponent);
