import React from "react";
import cn from "classnames";
import styles from "./status.module.scss";
import { ApiReview } from "../../types/ApiReview";

export interface StatusProps {
  apiReviewApproved?: ApiReview[`approved`];
}

const Status: React.FC<StatusProps> = ({ apiReviewApproved }) => {
  return (
    <div className={styles.status}>
      <div
        className={cn(
          styles.status_label,
          `${
            apiReviewApproved === true
              ? styles.status_color_approved
              : apiReviewApproved === false
              ? styles.status_color_rejected
              : styles.status_color_to_be_reviewed
          }`
        )}
      >
        {apiReviewApproved === true
          ? "Approved"
          : apiReviewApproved === false
          ? "Not Approved"
          : "To be Reviewed"}
      </div>
    </div>
  );
};

export { Status };
