export const NAV_SECTIONS = {
  base: "/",
  home: "/home",
  governance: "/governance",  
};

export const ROUTE_ENTITIES = {
  apis: "/apis",
  reviews: "/reviews",
  exceptions: "/exceptions",
  create: "/create",
  groups: "/groups",
  history: "/history",
  status: "/status",
  systems: "/systems",
};

export const ROUTES = {
  landing: NAV_SECTIONS.base,
  home: NAV_SECTIONS.home,
  governance: NAV_SECTIONS.governance,
  apiList: `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.apis}`,
  apiReviewList: `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.reviews}`,
  apiDetails: `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.apis}/:apiId${ROUTE_ENTITIES.groups}/:groupId`,
  apiReviewDetails: `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.reviews}/:apiId${ROUTE_ENTITIES.groups}/:groupId`,
  reviewHistory: `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.reviews}${ROUTE_ENTITIES.history}/:apiId${ROUTE_ENTITIES.groups}/:groupId`,
  exceptionHistory: `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.exceptions}${ROUTE_ENTITIES.history}/:apiId${ROUTE_ENTITIES.groups}/:groupId`,
  exceptionStatus: `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.exceptions}${ROUTE_ENTITIES.status}/:apiId${ROUTE_ENTITIES.groups}/:groupId`,
  systemDetails: `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.systems}/:systemCode`,
  scoreDetails: "/compliance-report",
  create: `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.create}`,
};

export const LINK_ENTITIES = {
  api: (apiId: string, groupId: string) =>
    `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.apis}/${apiId}${ROUTE_ENTITIES.groups}/${groupId}`,
  apiReview: (apiId: string, groupId: string) =>
    `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.reviews}/${apiId}${ROUTE_ENTITIES.groups}/${groupId}`,
  reviewHistory: (apiId: string, groupId: string) =>
    `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.reviews}${ROUTE_ENTITIES.history}/${apiId}${ROUTE_ENTITIES.groups}/${groupId}`,
  exceptionHistory: (apiId: string, groupId: string) =>
    `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.exceptions}${ROUTE_ENTITIES.history}/${apiId}${ROUTE_ENTITIES.groups}/${groupId}`,
  exceptionStatus: (apiId: string, groupId: string) =>
    `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.exceptions}${ROUTE_ENTITIES.status}/${apiId}${ROUTE_ENTITIES.groups}/${groupId}`,
  system: (systemCode: string) =>
    `${NAV_SECTIONS.governance}${ROUTE_ENTITIES.systems}/${systemCode}`,
  report: (apiId: string, groupId: string) =>
    `${ROUTES.scoreDetails}?groupid=${groupId}&apiid=${apiId}`,
};
