import { Endpoints } from "../../constants/endpoints";
import { httpRequest, OAP_API_PARAMS } from "../../utils/request";

export const getReport = async () =>
  await httpRequest(Endpoints.scoreDetails());

export const getApiReport = async (groupId: string, apiId: string) =>
  await httpRequest(
    Endpoints.apiReportByGroupIdApiId(groupId, apiId),
    OAP_API_PARAMS()
  );
