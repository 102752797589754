import React, { useEffect, useState } from "react";
import { ApiExceptionStatus } from "../../types/ApiException";
import { Api } from "../../types/Api";
import { Loader } from "../../components/Loader/Loader.component";
import { PageHeader } from "../../components/PageHeader/PageHeader.component";
import { PillMessages, Placeholder } from "../../constants/labels";
import styles from "./exceptionStatus.module.scss";
import { GoBackButton } from "../../components/GoBackButton/GoBackButton.component";
import {
  PillLabel,
  PillVariants,
} from "../../components/PillLabel/PillLabel.component";
import { DetailsExceptionStatusField } from "../../components/DetailsExceptionStatusField/DetailsExceptionStatusField.component";
import BorderedBox from "../../components/BorderedBox/BorderedBox.component";
import BoxTitle from "../../components/BoxTitle/BoxTitle.component";

export interface ExceptionStatusComponentProps {
  api?: Api;
  apisExceptionStatus: ApiExceptionStatus[];
  apiLoading: boolean;
}

export const ExceptionStatusComponent: React.FC<
  ExceptionStatusComponentProps
> = ({ api, apisExceptionStatus, apiLoading }) => {
  useEffect(() => {
    setApisExceptionStatusState(apisExceptionStatus);
  }, [apisExceptionStatus]);

  const [apisExceptionStatusState, setApisExceptionStatusState] =
    useState(apisExceptionStatus);

  if (apiLoading) {
    return <Loader />;
  } else if (
    (!api || !apisExceptionStatusState.length) &&
    !apiLoading
  ) {
    <div>
      <PillLabel
        variant={PillVariants.ERROR}
        text={PillMessages.missingException}
      />
    </div>;
  }

  return (
    <div className={styles.status_main_wrapper}>
      <div className={styles.status_header}>
        <PageHeader
          bookmark="API Exception Status"
          title={api?.id || Placeholder.NotAvailable}
        />
        <GoBackButton />
      </div>

      {!!apisExceptionStatusState.length &&
        apisExceptionStatusState?.map((apiExceptionStatusItem) => (
          <BorderedBox>
            <BoxTitle title="Exception Status"></BoxTitle>
            <DetailsExceptionStatusField
              apisExceptionStatus={apiExceptionStatusItem}
              key={apiExceptionStatusItem.uniqueId}
            />
          </BorderedBox>
        ))}
    </div>
  );
};
