import { ApiException, ApiExceptionStatus } from "../../types/ApiException";

export enum API_ACTIONS {
  LIST_EXCEPTION = "##APIS/LIST_EXCEPTION",
  LIST_EXCEPTION_STATUS = "##APIS/LIST_EXCEPTION_STATUS",
  FETCH_LIST_EXCEPTION_STATUS_BY_ID = "##APIS/FETCH_LIST_EXCEPTION_STATUS_BY_ID",
  FETCH_LIST_EXCEPTION_BY_ID = "##APIS/FETCH_LIST_EXCEPTION_BY_ID",
  FETCH_ONE_LATEST_EXCEPTION = "##APIS/FETCH_ONE_LATEST_EXCEPTION",
  POST_EXCEPTION = "##APIS/POST_EXCEPTION",
  SET_LIST_EXCEPTION = "##APIS/SET_LIST_EXCEPTION",
  SET_LIST_EXCEPTION_STATUS = "##APIS/SET_LIST_EXCEPTION_STATUS",
  SET_SELECTED_LIST_EXCEPTION_STATUS_BY_ID = "##APIS/SET_SELECTED_LIST_EXCEPTION_STATUS_BY_ID",
  SET_SELECTED_LIST_EXCEPTION_BY_ID = "##APIS/SET_SELECTED_LIST_EXCEPTION_BY_ID",
  SET_SELECTED_LATEST_EXCEPTION = "##APIS/SET_SELECTED_LATEST_EXCEPTION",
  SET_LOADING = "##APIS/SET_LOADING",
  SET_MESSAGE = "##APIS/SET_MESSAGE",
}

export interface ListApisExceptionActionType {
  type: API_ACTIONS.LIST_EXCEPTION;
}

export interface ListApisExceptionStatusActionType {
  type: API_ACTIONS.LIST_EXCEPTION_STATUS;
}

export interface PostExceptionActionType {
  type: API_ACTIONS.POST_EXCEPTION;
  payload: {
    data: ApiException;
    token: string;
  };
}

export interface FetchApisExceptionStatusListByIdActionType {
  type: API_ACTIONS.FETCH_LIST_EXCEPTION_STATUS_BY_ID;
  payload: {
    groupId: string;
    apiId: string;
  };
}

export interface FetchApisExceptionListByIdActionType {
  type: API_ACTIONS.FETCH_LIST_EXCEPTION_BY_ID;
  payload: {
    groupId: string;
    apiId: string;
    latest: boolean;
  };
}

export interface FetchApiLatestExceptionActionType {
  type: API_ACTIONS.FETCH_ONE_LATEST_EXCEPTION;
  payload: {
    groupId: string;
    apiId: string;
  };
}

export interface SetListApisExceptionActionType {
  type: API_ACTIONS.SET_LIST_EXCEPTION;
  payload: ApiException[];
}

export interface SetListApisExceptionStatusActionType {
  type: API_ACTIONS.SET_LIST_EXCEPTION_STATUS;
  payload: ApiExceptionStatus[];
}

export interface SetSelectedApisExceptionListByIdActionType {
  type: API_ACTIONS.SET_SELECTED_LIST_EXCEPTION_BY_ID;
  payload: ApiException[];
}

export interface SetSelectedApisExceptionStatusListByIdActionType {
  type: API_ACTIONS.SET_SELECTED_LIST_EXCEPTION_STATUS_BY_ID;
  payload: ApiExceptionStatus[];
}

export interface SetSelectedApiLatestExceptionActionType {
  type: API_ACTIONS.SET_SELECTED_LATEST_EXCEPTION;
  payload: ApiException;
}

export interface SetApisExceptionLoadingActionType {
  type: API_ACTIONS.SET_LOADING;
  payload: boolean;
}

export interface SetApisMessageActionType {
  type: API_ACTIONS.SET_MESSAGE;
  payload: string;
}

//To be exported to APILISTV1 Container and use in Condition for Filter
export const listApisExceptionAction = (): ListApisExceptionActionType => ({
  type: API_ACTIONS.LIST_EXCEPTION,
});

export const listApisExceptionStatusAction =
  (): ListApisExceptionStatusActionType => ({
    type: API_ACTIONS.LIST_EXCEPTION_STATUS,
  });

export const postExceptionAction = (
  data: ApiException,
  token: string
): PostExceptionActionType => ({
  type: API_ACTIONS.POST_EXCEPTION,
  payload: {
    data,
    token,
  },
});

export const fetchApisExceptionStatusByIdListAction = (
  groupId: string,
  apiId: string
): FetchApisExceptionStatusListByIdActionType => ({
  type: API_ACTIONS.FETCH_LIST_EXCEPTION_STATUS_BY_ID,
  payload: {
    groupId,
    apiId,
  },
});

export const fetchApisExceptionListAction = (
  groupId: string,
  apiId: string,
  latest: boolean
): FetchApisExceptionListByIdActionType => ({
  type: API_ACTIONS.FETCH_LIST_EXCEPTION_BY_ID,
  payload: {
    groupId,
    apiId,
    latest,
  },
});

export const fetchApiLatestExceptionAction = (
  groupId: string,
  apiId: string
): FetchApiLatestExceptionActionType => ({
  type: API_ACTIONS.FETCH_ONE_LATEST_EXCEPTION,
  payload: {
    groupId,
    apiId,
  },
});

export const setListApisExceptionAction = (
  apisException: ApiException[]
): SetListApisExceptionActionType => ({
  type: API_ACTIONS.SET_LIST_EXCEPTION,
  payload: apisException,
});

export const setListApisExceptionStatusAction = (
  apiExceptionStatus: ApiExceptionStatus[]
): SetListApisExceptionStatusActionType => ({
  type: API_ACTIONS.SET_LIST_EXCEPTION_STATUS,
  payload: apiExceptionStatus,
});

export const setSelectedApisExceptionStatusListByIdAction = (
  apiExceptionStatus: ApiExceptionStatus[]
): SetSelectedApisExceptionStatusListByIdActionType => ({
  type: API_ACTIONS.SET_SELECTED_LIST_EXCEPTION_STATUS_BY_ID,
  payload: apiExceptionStatus,
});

export const setSelectedApiLatestExceptionAction = (
  apiException: ApiException
): SetSelectedApiLatestExceptionActionType => ({
  type: API_ACTIONS.SET_SELECTED_LATEST_EXCEPTION,
  payload: apiException,
});

export const setSelectedApisExceptionListByIdAction = (
  apiException: ApiException[]
): SetSelectedApisExceptionListByIdActionType => ({
  type: API_ACTIONS.SET_SELECTED_LIST_EXCEPTION_BY_ID,
  payload: apiException,
});

export const setApisExceptionLoadingAction = (
  loading: boolean
): SetApisExceptionLoadingActionType => ({
  type: API_ACTIONS.SET_LOADING,
  payload: loading,
});

export const setApisMessageAction = (
  message: string
): SetApisMessageActionType => ({
  type: API_ACTIONS.SET_MESSAGE,
  payload: message,
});

export type ApiActionsTypes =
  | ListApisExceptionActionType
  | ListApisExceptionStatusActionType
  | PostExceptionActionType
  | FetchApisExceptionStatusListByIdActionType
  | FetchApisExceptionListByIdActionType
  | FetchApiLatestExceptionActionType
  | SetListApisExceptionActionType
  | SetListApisExceptionStatusActionType
  | SetSelectedApisExceptionStatusListByIdActionType
  | SetSelectedApiLatestExceptionActionType
  | SetSelectedApisExceptionListByIdActionType
  | SetApisExceptionLoadingActionType
  | SetApisMessageActionType;
