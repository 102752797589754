import React, { useCallback } from "react";
import cn from "classnames";
import { Api } from "../../types/Api";
import { ApiReview } from "../../types/ApiReview";
import { Card } from "../Card/Card.component";
import styles from "./apiCard.module.scss";
import { useNavigate } from "react-router-dom";
import { Placeholder } from "../../constants/labels";
import { LINK_ENTITIES } from "../../constants/routes";
import { Status } from "../Status/Status.component";
import { ApiExceptionStatus } from "../../types/ApiException";

export interface APICardStatusProps {
  api: Api;
  apiReviewApproved?: ApiReview["approved"];
  apiExceptionStatus?: ApiExceptionStatus["status"];
}
export const APICardStatus: React.FC<APICardStatusProps> = ({
  api,
  apiReviewApproved,
  apiExceptionStatus,
}) => {
  const navigate = useNavigate();
  const hasProduct = api.deepProduct !== Placeholder.NotAvailable;

  const handleClick = useCallback(() => {
    navigate(`${LINK_ENTITIES.apiReview(api.id, api.groupId)}`);
  }, [navigate, api]);

  return (
    <Card onClick={handleClick} className={styles.show_pointer}>
      <div className={styles.top}>
        <div className={styles.details}>
          <div className={styles.name} title={api.name}>
            {api.name}
          </div>
          <div
            className={cn(styles.hasProduct, {
              [styles.unavailable]: !hasProduct,
            })}
          >
            {hasProduct ? api.deepProduct : Placeholder.NotAvailable}
          </div>
          <div className={styles.platform}>{api.apiPlatform}</div>
        </div>
        <div className={styles.version}>{api.version}</div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottom_labels}>
          <div
            className={cn(
              styles.apiExceptionStatus,
              `${
                (apiExceptionStatus === "Exception Not Approved" &&
                  styles.apiExceptionStatus_color_not_approved) ||
                (apiExceptionStatus === "Exception Limit Near" &&
                  styles.apiExceptionStatus_color_limit_near) ||
                (apiExceptionStatus === "Approved" &&
                  styles.apiExceptionStatus_color_approved) ||
                ((apiExceptionStatus === "No Exception" ||
                  apiExceptionStatus === "No Sizing Information") &&
                  styles.apiExceptionStatus_color_no_size_info)
              }`
            )}
          >
            {apiExceptionStatus}
          </div>
          <div className={styles.bottom_status_layer}>
            <Status apiReviewApproved={apiReviewApproved} />
            <div
              className={cn(
                styles.layer,
                `${
                  (api.layer === "System" && styles.layer_color_system) ||
                  (api.layer === "Experience" &&
                    styles.layer_color_experience) ||
                  (api.layer === "Process" && styles.layer_color_process)
                }`
              )}
            >
              {api.layer}
            </div>
          </div>
        </div>

        <div className={styles.environments}>
          {api.environments.map((environment) => (
            <div key={environment}>{environment}</div>
          ))}
        </div>
      </div>
    </Card>
  );
};
