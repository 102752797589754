import { useState } from "react";

export function useCollapsable(): [
  collapsed: boolean,
  toggleCollapsed: Function
] {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return [collapsed, toggleCollapsed];
}
