import { ApiException, ApiExceptionStatus } from "../../types/ApiException";
import { RootState } from "../rootReducer";

export const apisListExceptionSelector = (state: RootState): ApiException[] => {
  return state.apisException.resultsException;
};

export const apisListExceptionStatusSelector = (state: RootState): ApiExceptionStatus[] => {
  return state.apisException.resultsExceptionStatus;
};

export const selectedApisExceptionStatusListSelector = (state: RootState): ApiExceptionStatus[] => {
  return state.apisException.selectedExceptionStatusListById;
};

export const selectedApisExceptionListSelector = (state: RootState): ApiException[] => {
  return state.apisException.selectedExceptionListById;
};

export const selectedApiLatestExceptionSelector = (
  state: RootState
): ApiException | undefined => state.apisException.selectedLatestException;

export const apisExceptionStatusUniqueValuesSelector = (state: RootState): string[] =>
  state.apisException.statusUniqueValues;

export const apisExceptionLoadingSelector = (state: RootState): boolean =>
  state.apisException.loading;

export const apisExceptionMessageSelector = (state: RootState): string =>
  state.apisException.exceptionMessage;
