import { call, put, takeEvery } from "redux-saga/effects";
import {
  SYSTEMS_ACTIONS,
  FetchSystemActionType,
  setSystemsLoadingAction,
  setListSystemsAction,
  setSelectedSystemAction,
  GetSystemsSuggestionsActionType,
  setSuggestionSystemsAction,
  AssociateSystemActionType,
} from "./actions";

import { System } from "../../types/System";
import {
  associateSystem,
  getSystemById,
  getSystems,
  getSystemSuggestions,
} from "./requests";
import { setApiSystemCodeAction } from "../apis/actions";

export function* listSystemsSaga() {
  yield put(setSystemsLoadingAction(true));
  const result: System[] = yield call(getSystems);
  yield put(setListSystemsAction(result));
}

export function* fetchSystemSaga({
  payload: { systemId },
}: FetchSystemActionType) {
  yield put(setSystemsLoadingAction(true));
  const result: System = yield call(getSystemById, systemId);
  yield put(setSelectedSystemAction(result));
}

export function* getSuggestionsSaga({
  payload: { groupId, apiId },
}: GetSystemsSuggestionsActionType) {
  yield put(setSystemsLoadingAction(true));
  const result: System[] = yield call(getSystemSuggestions, groupId, apiId);
  yield put(setSuggestionSystemsAction(result));
}

export function* associateSystemSaga({
  payload: { groupId, apiId, version, systemCode },
}: AssociateSystemActionType) {
  yield put(setSystemsLoadingAction(true));
  const result: System = yield call(
    associateSystem,
    groupId,
    apiId,
    version,
    systemCode
  );
  yield put(setApiSystemCodeAction(result.systemCode));
  yield put(setSelectedSystemAction(result));
}

function* systemsSaga() {
  yield takeEvery(SYSTEMS_ACTIONS.LIST, listSystemsSaga);
  yield takeEvery(SYSTEMS_ACTIONS.FETCH_ONE, fetchSystemSaga);
  yield takeEvery(SYSTEMS_ACTIONS.GET_SUGGESTIONS, getSuggestionsSaga);
  yield takeEvery(SYSTEMS_ACTIONS.ASSOCIATE_SYSTEM, associateSystemSaga);
}

export default systemsSaga;
