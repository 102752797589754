import { Api } from "../../types/Api";

export enum API_ACTIONS {
  LIST = "##APIS/LIST",
  LIST_API_FILTER = "##APIS/LIST_API_FILTER",
  FETCH_ONE = "##APIS/FETCH_ONE",
  SET_LIST = "##APIS/SET_LIST",
  SET_LIST_API_FILTER = "##APIS/SET_LIST_API_FILTER",
  SET_SELECTED = "##APIS/SET_SELECTED",
  SET_LOADING = "##APIS/SET_LOADING",
  SET_FILTER_VALUE = "##APIS/SET_FILTER_VALUE",
  SET_SYSTEM_CODE = "##APIS/SET_SYSTEM_CODE",
}

export interface ListApisActionType {
  type: API_ACTIONS.LIST;
}

export interface ListApisFilterActionType {
  type: API_ACTIONS.LIST_API_FILTER;
  payload: {
    layer: string;
    env: string;
    deepProduct: string;
  };
}

//Set to Payload
export interface SetListApisActionType {
  type: API_ACTIONS.SET_LIST;
  payload: Api[];
}

export interface SetListApisFilterActionType {
  type: API_ACTIONS.SET_LIST_API_FILTER;
  payload: {
    layer: string;
    env: string;
    deepProduct: string;
  };
}

export interface FetchApiActionType {
  type: API_ACTIONS.FETCH_ONE;
  payload: {
    groupId: string;
    apiId: string;
  };
}

export interface SetSelectedApiActionType {
  type: API_ACTIONS.SET_SELECTED;
  payload: Api;
}

export interface SetApisLoadingActionType {
  type: API_ACTIONS.SET_LOADING;
  payload: boolean;
}

export interface SetApisFilterValueActionType {
  type: API_ACTIONS.SET_FILTER_VALUE;
  payload: string;
}

export interface SetApiSystemCodeActionType {
  type: API_ACTIONS.SET_SYSTEM_CODE;
  payload: string;
}

//To be exported to APILISTV1 Container and use in Condition for Filter
export const listApisAction = (): ListApisActionType => ({
  type: API_ACTIONS.LIST,
});

export const listApisAllAction = (
  layer: string,
  env: string,
  deepProduct: string
): ListApisFilterActionType => ({
  type: API_ACTIONS.LIST_API_FILTER,
  payload: { layer, env, deepProduct },
});

//Set Payload
export const setListApisAction = (apis: Api[]): SetListApisActionType => ({
  type: API_ACTIONS.SET_LIST,
  payload: apis,
});

export const setListApisAllAction = (
  layer: string,
  env: string,
  deepProduct: string
): SetListApisFilterActionType => ({
  type: API_ACTIONS.SET_LIST_API_FILTER,
  payload: { layer, env, deepProduct },
});

export const fetchApiAction = (
  groupId: string,
  apiId: string
): FetchApiActionType => ({
  type: API_ACTIONS.FETCH_ONE,
  payload: {
    groupId,
    apiId,
  },
});

export const setSelectedApiAction = (api: Api): SetSelectedApiActionType => ({
  type: API_ACTIONS.SET_SELECTED,
  payload: api,
});

export const setApisLoadingAction = (
  loading: boolean
): SetApisLoadingActionType => ({
  type: API_ACTIONS.SET_LOADING,
  payload: loading,
});

export const setApisFilterValueAction = (
  value: string
): SetApisFilterValueActionType => ({
  type: API_ACTIONS.SET_FILTER_VALUE,
  payload: value,
});

export const setApiSystemCodeAction = (
  systemCode: string
): SetApiSystemCodeActionType => ({
  type: API_ACTIONS.SET_SYSTEM_CODE,
  payload: systemCode,
});

export type ApiActionsTypes =
  | ListApisActionType
  | ListApisFilterActionType
  | SetListApisActionType
  | FetchApiActionType
  | SetSelectedApiActionType
  | SetApisLoadingActionType
  | SetApisFilterValueActionType
  | SetApiSystemCodeActionType;