import { all } from "redux-saga/effects";
import apisSaga from "./apis/sagas";
import apisReviewSaga from "./apisReview/sagas";
import apisExceptionSaga from "./apisException/sagas";
import authSaga from "./auth/sagas";
import complianceSaga from "./compliance/sagas";
import systemsSaga from "./systems/sagas";

export default function* rootSaga() {
  yield all([complianceSaga(), apisSaga(), apisReviewSaga(), apisExceptionSaga(), systemsSaga(), authSaga()]);
}
