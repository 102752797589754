import React from "react";
import styles from "./compliance.module.scss";
import { CriteriaListItem } from "../../components/CriteriaListItem/CriteriaListItem.component";
import { Compliance } from "../../types/Compliance";
import { Api } from "../../types/Api";
import { ScoreHeader } from "../../components/ScoreHeader/ScoreHeader.component";
import { Placeholder } from "../../constants/labels";
import { Loader } from "../../components/Loader/Loader.component";
import {
  PillLabel,
  PillVariants,
} from "../../components/PillLabel/PillLabel.component";

export interface ComplianceComponentProps {
  compliance?: Compliance;
  api?: Api;
  loading: boolean;
}

export const ScoreDetailsComponent: React.FC<ComplianceComponentProps> = ({
  compliance,
  api = {},
  loading,
}) => (
  <div className={styles.compliance__wrapper}>
    {loading && <Loader />}
    {!loading && !compliance && (
      <PillLabel text="API not found!" variant={PillVariants.ERROR} />
    )}
    {!loading && compliance && (
      <>
        <ScoreHeader
          compliance={compliance}
          apiName={api.name || Placeholder.NotAvailable}
          apiProduct={api.deepProduct || Placeholder.NotAvailable}
        />
        <div className={styles.compliance__results}>
          <div className={styles.compliance__results_title}>
            User Acceptance Criteria
          </div>
          {compliance.criterias.map((criteria) => (
            <CriteriaListItem key={criteria.name} criteria={criteria} />
          ))}
        </div>
      </>
    )}
  </div>
);
