import { System } from "../../types/System";

export enum SYSTEMS_ACTIONS {
  LIST = "##SYSTEMS/LIST",
  SET_LIST = "##SYSTEMS/SET_LIST",
  SET_SUGGESTIONS = "##SYSTEMS/SET_SUGGESTIONS",
  FETCH_ONE = "##SYSTEMS/FETCH_ONE",
  SET_SELECTED = "##SYSTEMS/SET_SELECTED",
  CLEAR_SELECTED = "##SYSTEMS/CLEAR_SELECTED",
  SET_LOADING = "##SYSTEMS/SET_LOADING",
  SET_FILTER_VALUE = "##SYSTEMS/SET_FILTER_VALUE",
  GET_SUGGESTIONS = "##SYSTEMS/GET_SUGGESTIONS",
  ASSOCIATE_SYSTEM = "##SYSTEMS/ASSOCIATE_SYSTEM_TO_API",
}

export interface ListSystemsActionType {
  type: SYSTEMS_ACTIONS.LIST;
}

export interface SetListSystemsActionType {
  type: SYSTEMS_ACTIONS.SET_LIST;
  payload: System[];
}

export interface SetSuggestionSystemsActionType {
  type: SYSTEMS_ACTIONS.SET_SUGGESTIONS;
  payload: System[];
}

export interface FetchSystemActionType {
  type: SYSTEMS_ACTIONS.FETCH_ONE;
  payload: {
    systemId: string;
  };
}

export interface SetSelectedSystemActionType {
  type: SYSTEMS_ACTIONS.SET_SELECTED;
  payload: System;
}

export interface ClearSelectedSystemActionType {
  type: SYSTEMS_ACTIONS.CLEAR_SELECTED;
}

export interface SetSystemsLoadingActionType {
  type: SYSTEMS_ACTIONS.SET_LOADING;
  payload: boolean;
}

export interface SetSystemsFilterValueActionType {
  type: SYSTEMS_ACTIONS.SET_FILTER_VALUE;
  payload: string;
}

export interface GetSystemsSuggestionsActionType {
  type: SYSTEMS_ACTIONS.GET_SUGGESTIONS;
  payload: {
    groupId: string;
    apiId: string;
  };
}

export interface AssociateSystemActionType {
  type: SYSTEMS_ACTIONS.ASSOCIATE_SYSTEM;
  payload: {
    groupId: string;
    apiId: string;
    version: string;
    systemCode: string;
  };
}

export const listSystemsAction = (): ListSystemsActionType => ({
  type: SYSTEMS_ACTIONS.LIST,
});

export const setListSystemsAction = (
  systems: System[]
): SetListSystemsActionType => ({
  type: SYSTEMS_ACTIONS.SET_LIST,
  payload: systems,
});

export const setSuggestionSystemsAction = (
  systems: System[]
): SetSuggestionSystemsActionType => ({
  type: SYSTEMS_ACTIONS.SET_SUGGESTIONS,
  payload: systems,
});

export const fetchSystemAction = (systemId: string): FetchSystemActionType => ({
  type: SYSTEMS_ACTIONS.FETCH_ONE,
  payload: {
    systemId,
  },
});

export const setSelectedSystemAction = (
  system: System
): SetSelectedSystemActionType => ({
  type: SYSTEMS_ACTIONS.SET_SELECTED,
  payload: system,
});

export const clearSelectedSystemAction = (): ClearSelectedSystemActionType => ({
  type: SYSTEMS_ACTIONS.CLEAR_SELECTED,
});

export const setSystemsLoadingAction = (
  loading: boolean
): SetSystemsLoadingActionType => ({
  type: SYSTEMS_ACTIONS.SET_LOADING,
  payload: loading,
});

export const setSystemsFilterValueAction = (
  value: string
): SetSystemsFilterValueActionType => ({
  type: SYSTEMS_ACTIONS.SET_FILTER_VALUE,
  payload: value,
});

export const getSystemsSuggestionsAction = (
  groupId: string,
  apiId: string
): GetSystemsSuggestionsActionType => ({
  type: SYSTEMS_ACTIONS.GET_SUGGESTIONS,
  payload: {
    groupId,
    apiId,
  },
});

export const associateSystemAction = (
  groupId: string,
  apiId: string,
  version: string,
  systemCode: string
): AssociateSystemActionType => ({
  type: SYSTEMS_ACTIONS.ASSOCIATE_SYSTEM,
  payload: {
    groupId,
    apiId,
    version,
    systemCode,
  },
});

export type SystemActionsTypes =
  | ListSystemsActionType
  | SetListSystemsActionType
  | SetSuggestionSystemsActionType
  | FetchSystemActionType
  | SetSelectedSystemActionType
  | ClearSelectedSystemActionType
  | SetSystemsLoadingActionType
  | SetSystemsFilterValueActionType
  | GetSystemsSuggestionsActionType
  | AssociateSystemActionType;
