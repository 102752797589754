import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./goBackButton.module.scss";

const GoBackButton: React.FC = () => {
  const navigate = useNavigate();

  return (
    <button className={styles.button} onClick={() => navigate(-1)}>
      <p>&#129144;</p> Go Back
    </button>
  );
};

export { GoBackButton };
