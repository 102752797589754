import React from "react";
import cn from "classnames";
import styles from "./card.module.scss";

export type CardProps = JSX.IntrinsicElements["div"];

export const Card: React.FC<CardProps> = ({ children, ...props }) => (
  <div
    data-testid="CardComponent"
    {...props}
    className={cn(styles.card, props.className)}
  >
    {children}
  </div>
);
