import React, { useEffect, useMemo, useState } from "react";
import { Loader } from "../../components/Loader/Loader.component";
import { PageHeader } from "../../components/PageHeader/PageHeader.component";
import { PillMessages, Placeholder } from "../../constants/labels";
import styles from "./reviewHistory.module.scss";
import { ApiReview } from "../../types/ApiReview";
import { Pagination } from "../../components/Pagination/Pagination.component";
import { FilterCheckbox } from "../../components/FilterCheckbox/FilterCheckbox.component";
import { GoBackButton } from "../../components/GoBackButton/GoBackButton.component";
import { DetailsReviewField } from "../../components/DetailsReviewField/DetailsReviewField.component";
import BorderedBox from "../../components/BorderedBox/BorderedBox.component";
import BoxTitle from "../../components/BoxTitle/BoxTitle.component";
import {
  PillLabel,
  PillVariants,
} from "../../components/PillLabel/PillLabel.component";

export interface ReviewHistoryComponentProps {
  apiLatestReview?: ApiReview;
  apisReview: ApiReview[];
  apiLoading: boolean;
}

export const ReviewHistoryComponent: React.FC<ReviewHistoryComponentProps> = ({
  apiLatestReview,
  apisReview,
  apiLoading,
}) => {
  useEffect(() => {
    setApis(apisReview);
  }, [apisReview]);

  //Start Filter Approved
  const [apis, setApis] = useState(apisReview);
  const [checked, setChecked] = useState(false);

  const handleCheck = (checked: boolean) => {
    setCurrentPage(1);
    if (checked === true) {
      setChecked(true);
      const data = apisReview
        .filter((dataFilter) => dataFilter.approved === true)
        .map((filteredName) => {
          return filteredName;
        });
      setApis(data);
    } else {
      setChecked(false);
      setApis(apisReview);
    }
  };
  //End Filter Approved

  //Start Pagination
  const [currentPage, setCurrentPage] = useState(1);

  const apisPagination: ApiReview[] = useMemo(() => {
    const firstPageIndex = currentPage - 1;
    const lastPageIndex = firstPageIndex + 1;
    return apis.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, apis]);
  //End Pagination

  if (apiLoading) {
    return <Loader />;
  } else if (
    (!apiLatestReview || !apisPagination.length || !apisReview.length) &&
    !apiLoading
  ) {
    <div>
      <PillLabel
        variant={PillVariants.ERROR}
        text={PillMessages.missingReview}
      />
    </div>;
  }

  return (
    <div className={styles.history_main_wrapper}>
      <div className={styles.history_header}>
        <PageHeader
          bookmark="API Reviews"
          title={apiLatestReview?.apiId || Placeholder.NotAvailable}
        />
        <GoBackButton />
      </div>

      <>
        <div className={styles.history_filter}>
          <FilterCheckbox
            id="1"
            title="Approved"
            name="approved"
            handleCheck={(e: any) => handleCheck(e.target.checked)}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={!apis.length ? 1 : apis.length}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>

        <BorderedBox>
          <BoxTitle title="Reviews"></BoxTitle>
          {checked === true && !apis.length ? (
            <div>
              <PillLabel
                variant={PillVariants.ERROR}
                text={PillMessages.noApprovedFound}
              />
            </div>
          ) : (
            apisPagination?.map((apiReview, index) => (
              <DetailsReviewField apiLatestReview={apiReview} key={index} />
            ))
          )}
        </BorderedBox>
      </>
    </div>
  );
};
