import { Compliance } from "../../types/Compliance";

export enum COMPLIANCE_ACTIONS {
  FETCH_REPORT = "##COMPLIANCE/FETCH_REPORT",
  SET_REPORT = "##COMPLIANCE/SET_REPORT",
  SET_LOADING = "##COMPLIANCE/SET_LOADING",
  FETCH_API_REPORT = "##COMPLIANCE/FETCH_PEPORT_BY_ID",
}

export interface FetchComplianceReportActionType {
  type: COMPLIANCE_ACTIONS.FETCH_REPORT;
}

export interface SetComplianceReportActionType {
  type: COMPLIANCE_ACTIONS.SET_REPORT;
  payload: Compliance;
}

export interface SetComplianceLoadingActionType {
  type: COMPLIANCE_ACTIONS.SET_LOADING;
  payload: boolean;
}

export interface FetchApiReportActionType {
  type: COMPLIANCE_ACTIONS.FETCH_API_REPORT;
  payload: {
    groupId: string;
    apiId: string;
  };
}

// export const fetchComplianceReportAction =
//   (): FetchComplianceReportActionType => ({
//     type: COMPLIANCE_ACTIONS.FETCH_REPORT,
//   });

export const setComplianceReportAction = (
  compliance: Compliance
): SetComplianceReportActionType => ({
  type: COMPLIANCE_ACTIONS.SET_REPORT,
  payload: compliance,
});

export const setComplianceLoadingAction = (
  loading: boolean
): SetComplianceLoadingActionType => ({
  type: COMPLIANCE_ACTIONS.SET_LOADING,
  payload: loading,
});

export const fetchApiReportAction = (
  groupId: string,
  apiId: string
): FetchApiReportActionType => ({
  type: COMPLIANCE_ACTIONS.FETCH_API_REPORT,
  payload: { groupId, apiId },
});

export type ComplianceActionsTypes =
  | FetchComplianceReportActionType
  | SetComplianceReportActionType
  | SetComplianceLoadingActionType
  | FetchApiReportActionType;
