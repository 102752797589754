import React from "react";
import cn from "classnames";
import styles from "./scorePercentageCard.module.scss";
import { SCORE_LEVEL } from "../../types/Compliance";
import { decimalToPercentage, getScoreLevel } from "../../utils/calcs";

export interface ScorePercentageCardProps {
  value: number;
}

const MAP_LEVELS_TO_CLASSES = {
  [SCORE_LEVEL.MEDIUM]: styles.medium,
  [SCORE_LEVEL.HIGH]: styles.high,
};

export const ScorePercentageCard: React.FC<ScorePercentageCardProps> = ({
  value,
}) => {
  const scoreClass = MAP_LEVELS_TO_CLASSES[getScoreLevel(value)];
  const scorePercentage = decimalToPercentage(value);

  return (
    <div className={styles.scorePercentage__card}>
      <div className={styles.scorePercentage__title}>Score</div>
      <div
        className={styles.scorePercentage__percentage}
      >{`${scorePercentage}%`}</div>
      <div className={styles.scorePercentage__bars}>
        <div className={styles.scorePercentage__bars_background}></div>
        <div
          className={cn(styles.scorePercentage__bars_value, scoreClass)}
          style={{ width: `${scorePercentage}%` }}
        ></div>
      </div>
    </div>
  );
};
